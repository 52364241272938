import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

export default function CloseIcon ({
  inverted,
  description = formatMessage('Close'),
  ...restProps
}) {
  return (
    <div className="CloseIcon">
      <span className="screenreader-only">{description}</span>
      {inverted ? (
        <svg viewBox="0 0 9 9" {...restProps}>
          <title>{description}</title>
          <path
            d="M6.97.577l1.3 1.298-2.572 2.57 2.56 2.56-1.3 1.298L4.4 5.743 1.83 8.316.53 7.017l2.572-2.57-2.56-2.56L1.843.59 4.4 3.147 6.97.578z"
            fill="#333"
            fillRule="evenodd"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 14 14" {...restProps}>
          <title>{description}</title>
          <path d="M10.598,10.472 C10.214,10.869 9.582,10.881 9.184,10.497 L7.025,8.414 L4.942,10.573 C4.558,10.971 3.925,10.982 3.529,10.599 C3.131,10.215 3.119,9.582 3.503,9.185 L5.586,7.025 L3.426,4.942 C3.029,4.56 3.017,3.926 3.401,3.528 C3.785,3.131 4.418,3.12 4.815,3.503 L6.974,5.586 L9.057,3.427 C9.441,3.029 10.074,3.019 10.471,3.401 C10.869,3.785 10.881,4.418 10.497,4.815 L8.414,6.975 L10.573,9.058 C10.97,9.441 10.982,10.074 10.598,10.472 L10.598,10.472 Z M6.968,0 C3.12,0 0,3.134 0,7 C0,10.866 3.12,14 6.968,14 C10.817,14 13.937,10.866 13.937,7 C13.937,3.134 10.817,0 6.968,0 L6.968,0 Z" />
        </svg>
      )}
    </div>
  );
}

CloseIcon.propTypes = {
  description: PropTypes.string,
  inverted: PropTypes.bool
};
