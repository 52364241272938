import Router from 'middle-router';
import ReactDOM from 'react-dom';
import RouterActions from './actions/router';

// middleware
import handleSession from './middleware/handle-session';
import requireSession from './middleware/require-session';

// routes
import tos from './routes/tos';
import errors from './routes/errors';
import setupComplete from './routes/setup-complete';
import sharedObjects from './routes/shared-objects';
import updates from './routes/updates';
import imported from './routes/imported';
import setup from './routes/setup';
import share from './routes/share';
import learningResource from './routes/learning-resource';
import edit from './routes/edit';
import search from './routes/search';
import accountSettings from './routes/account/settings';
import accountResources from './routes/account/resources';
import consortiums from './routes/account/consortiums';
import consortium from './routes/account/consortium';
import notFound from './routes/not-found';
import accountGroups from './routes/account/groups';
import accountGroup from './routes/account/group';
import stats from './routes/account/stats';
import favorites from './routes/favorites';
import favoriteResources from './routes/favorite-resources';
import firstTimeUser from './routes/first-time-user';
import { redirectToLogin } from './middleware/login-redirect';
const router = Router()
  .use(RouterActions.match)
  .use('/errors/:type', errors)
  .use('/setup-complete', setupComplete)
  .use('/first-time-user', firstTimeUser)
  .use('/login', ({ resolve }) => redirectToLogin() && resolve())
  .use(handleSession)
  .use('/', RouterActions.matchResult, search)
  .use('/search', RouterActions.matchResult, search)
  .use(
    '/resources/:id/:tab(summary|assignments|discussions|modules|outcomes|pages|quizzes|version-notes)',
    learningResource
  )
  .use('/resources/:id', learningResource)
  .use('/tos', tos)
  .use(requireSession)
  .use('/shared', RouterActions.matchResult, sharedObjects)
  .use('/updates', updates)
  .use('/imported', RouterActions.matchResult, imported)
  .use('/setup', setup)
  .use('/share', share)
  .use('/resources/:id/edit', edit)
  .use('/account/settings', accountSettings)
  .use('/account/resources', RouterActions.matchResult, accountResources)
  .use('/account/consortiums', consortiums)
  .use('/account/consortiums/:consortiumId', consortium)
  .use('/account/groups', accountGroups)
  .use('/account/groups/:id', accountGroup)
  .use('/account/stats', stats)
  .use('/favorites', favorites)
  .use('/favorite-resources', favoriteResources)
  .use(notFound);

const APP = document.getElementById('application');

function render (view) {
  // TODO: wrap the app in StrictMode and fix the upcoming warnings
  // to future proof it
  ReactDOM.render(view, APP);
}

router.on('route', async function (args, routing) {
  args.render = render;
  routing.catch(error => console.error(error));
});

export default router;
