import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatMessage from 'format-message';

export default class Loading extends React.Component {
  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  }

  render () {
    let { className, ...rest } = this.props;
    className = classNames('Loading', className);

    return (
      <span {...rest} className={className}>
        <span className="screenreader-only">{formatMessage('Loading...')}</span>
        <span className="Loading-0" />
        <span className="Loading-1" />
        <span className="Loading-2" />
      </span>
    );
  }
}
