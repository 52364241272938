import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import IconOutcomes from '@instructure/ui-icons/lib/IconOutcomesLine';
import IconCheck from '@instructure/ui-icons/lib/IconCheckLine';
import IconShare from '@instructure/ui-icons/lib/IconShareLine';
import IconTag from '@instructure/ui-icons/lib/IconTagLine';
import IconAttachMedia from '@instructure/ui-icons/lib/IconAttachMediaSolid';

import Grade from '../../../models/grade';
import Subject from '../../../models/subject';
import ResourcePreview from '../preview/resource';
import formatMessage from 'format-message';
import formatDuration from '../../../common/format-duration';
import usGrades from '../forms/grade-schemes/us';
import { focusIfTabParamPresent } from '../../../effects.js';
import CsvOutcomes from './csv-outcomes';
import Avatar from '../../common/avatar';
import Link from '../../common/link';

const ResourceSummary = createReactClass({
  displayName: 'ResourceSummary',

  propTypes: {
    resource: PropTypes.object,
    reviews: PropTypes.array,
    session: PropTypes.object,
    showPreview: PropTypes.bool
  },

  componentDidMount () {
    focusIfTabParamPresent(ReactDOM.findDOMNode(this));
  },

  componentDidUpdate () {
    focusIfTabParamPresent(ReactDOM.findDOMNode(this));
  },

  onClickReviewLink (evt) {
    const input = ReactDOM.findDOMNode(this).querySelector('.lor-rating-radio');

    if (input) {
      input.focus();
      return;
    }

    // TODO: great candidate for a flux action
    const myReview = this.refs.reviews.refs.myReview;
    if (myReview) {
      myReview.onClickEdit(evt);
    }
  },

  getScopeLabels () {
    const { userId } = this.props.session;
    return this.props.resource.scopes
      .map(({ id: scopeId, name }) => {
        if (scopeId === 'PUBLIC') return formatMessage('Public');
        if (scopeId === userId) return formatMessage('Private');
        return name;
      })
      .filter(scope => !!scope);
  },

  render () {
    const { resource, session } = this.props;
    const version = (resource.versions || [])[0] || {};
    const isMedia = resource.type === 'video' || resource.type === 'audio';
    const hasOutcomes = resource.outcomes && resource.outcomes.length > 0;

    const gradeIds = this.props.resource.gradeIds || [];
    const sharedWithAllGrades = usGrades.every(usGrade => {
      return gradeIds.some(grade => usGrade.value === grade);
    });

    const authors = resource.authors.map(author => {
      let authorDisplay;
      if (author.name.indexOf('@') > -1) {
        const [username, domain] = author.name.split('@');
        authorDisplay = (
          <span>
            {username}
            <wbr />@<wbr />
            {domain}
          </span>
        );
      } else {
        authorDisplay = author.name;
      }
      return (
        <li key={author.id} className="lor-course-author" data-heap-redact-text>
          <Avatar className="lor-course-author-avatar" data-heap-redact-attributes="url" url={author.avatarUrl} />
          <Link
            isWithinText
            data-heap-redact-attributes="href"
            className="lor-course-author-link"
            href={`/search?authorIds=${author.id}&authorName=${author.name}`}
          >
            {authorDisplay}
          </Link>
        </li>
      );
    });

    const scopeLabels = this.getScopeLabels() || [];

    return (
      <div className="lor-block-focusable" tabIndex="-1">
        <div className="lor-course-description">
          <h2 className="lor-course-details-heading screenreader-only">
            {formatMessage('Summary')}
          </h2>

          <div className="lor-course-description-body">
            {this.props.resource.description}
          </div>

          {authors && (
            <div className="lor-course-summary-section">
              <h3>{formatMessage('Author(s)')}</h3>
              <ul className="lor-course-authors-list">{authors}</ul>
            </div>
          )}

          <div className="lor-course-summary-section lor-course-account">
            <h3>{formatMessage('Account')}</h3>
            <p className="lor-course-account-name">
              <Link
                isWithinText
                data-heap-redact-attributes="href"
                data-heap-redact-text
                className="lor-course-account-link"
                href={`/search?accountId=${
                  this.props.resource.account.id
                }&accountName=${this.props.resource.account.name}`}
              >
                {this.props.resource.account.name}
              </Link>
            </p>
          </div>

          <hr aria-hidden="true" />

          {this.props.resource.subjectIds && (
            <div className="lor-course-summary-section">
              <h3>{formatMessage('Subjects')}</h3>
              <p>
                {Subject.getLabels(this.props.resource.subjectIds)
                  .map(tag => (
                    <span className="lor-csv-value" key={`csv-${tag}`}>
                      {tag}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr])}
              </p>
            </div>
          )}

          {this.props.resource.gradeIds && (
            <div className="lor-course-summary-section">
              <h3>
                <IconCheck /> {formatMessage('Grade/Level')}
              </h3>
              <p className="lor-csv-GradeLevel">
                {(sharedWithAllGrades
                  ? [formatMessage('Any')]
                  : Grade.getLabels(this.props.resource.gradeIds)
                )
                  .map(tag => (
                    <span className="lor-csv-value" key={`csv-${tag}`}>
                      {tag}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr])}
              </p>
            </div>
          )}

          {this.props.resource &&
            Array.isArray(this.props.resource.tags) &&
            this.props.resource.tags.length > 0 && (
            <div className="lor-course-summary-section">
              <h3>
                <IconTag /> {formatMessage('Tags')}
              </h3>
              <p className="lor-course-tags">
                {this.props.resource.tags
                  .map(tag => (
                    <span className="Tag" key={`tag-${tag}`}>
                      {tag}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr])}
              </p>
            </div>
          )}

          {hasOutcomes && (
            <div className="lor-course-summary-section">
              <h3>
                <IconOutcomes /> {formatMessage('Outcomes')}
              </h3>
              <CsvOutcomes
                session={session}
                header={formatMessage('Outcomes')}
                values={this.props.resource.outcomes}
              />
            </div>
          )}

          {isMedia && version.mediaObjectDuration && (
            <div className="lor-course-summary-section">
              <h3>
                <IconAttachMedia /> {formatMessage('Duration')}
              </h3>
              <p>{formatDuration(version.mediaObjectDuration)}</p>
            </div>
          )}

          <div className="lor-course-summary-section">
            <h3>
              <IconShare /> {formatMessage('Shared With')}
            </h3>
            <p className="lor-csv-SharedWith">
              {scopeLabels.length &&
                scopeLabels
                  .map(tag => (
                    <span className="lor-csv-value" key={`csv-${tag}`} data-heap-redact-text>
                      {tag}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr])}
            </p>
          </div>
        </div>

        {this.props.showPreview && (
          <ResourcePreview resource={this.props.resource} />
        )}
      </div>
    );
  }
});

export default ResourceSummary;
