import React from 'react';
import formatMessage from 'format-message';
import Select from '@instructure/ui-forms/lib/components/Select';
import { IconAdd } from '@instructure/ui-icons/lib/IconAddLine';

export default class CanvasResourceSelector extends React.Component {
  render () {
    const { canvasResourceType } = this.props;
    if (canvasResourceType !== 'module' &&
        canvasResourceType !== 'assignment') {
      return null;
    }
    const title = (canvasResourceType === 'module') ? formatMessage('Place in Module') : formatMessage('Place in Group');
    const optionList = this.props.availableCanvasResources
      .map(resource => {
        const icon = (resource.id === '_new') ? IconAdd : null;
        return <option key={resource.id} value={resource.id} icon={icon}>{resource.name}</option>;
      });
    return (<div data-testid="canvas-resource-selector" className="Canvas-resource-select-component" role="banner">
      <Select
        className="Canvas-resource-select"
        data-automation="CanvasResourceSelect"
        name="canvasResourceSelect"
        aria-label={title}
        title={title}
        onChange={this.props.onChangeHandler}
        selectedOption={this.props.currentSelected}
        renderLabel={title}
        visibleOptionsCount={Math.min(8, this.props.availableCanvasResources.length + 1)}
      >
        {optionList}
      </Select>
    </div>);
  }
}
