import React from 'react';
import formatMessage from 'format-message';

export default function withFavoriteResourceImportTargetHandler (
  WrappedComponent
) {
  return class FavoriteResourceImportTargetHandler extends React.Component {
    constructor (props) {
      super(props);

      this.state = this.getDefaultState();
    }

    getDefaultState = () => {
      const {
        availableCanvasResources
      } = this.props.session;
      const mapResourceToOption = ({ id, name }) => ({
        value: id,
        label: name
      });
      const availableCanvasResourceTargets = availableCanvasResources || [];

      const newTarget = { id: '_new', name: '＋ ' + formatMessage('Create new') };

      if (this.isCanvasResourceSelectAvailable()) {
        return {
          importTargets: [newTarget, ...availableCanvasResourceTargets],
          currentImportTarget: mapResourceToOption(newTarget)
        };
      }
      if (availableCanvasResourceTargets.length >= 1) {
        return {
          importTargets: [...availableCanvasResourceTargets],
          currentImportTarget: mapResourceToOption(availableCanvasResourceTargets[0])
        };
      }
      return {
        importTargets: [],
        currentImportTarget: null
      };
    }

    isCanvasResourceSelectAvailable = () => {
      const {
        canvasResourceType,
        allowCanvasResourceSelection
      } = this.props.session;
      if (!this.props.featureFlags) {
        return false;
      }
      const { flags } = this.props.featureFlags;

      return (
        (canvasResourceType === 'module' ||
          canvasResourceType === 'assignment') &&
        allowCanvasResourceSelection &&
        flags.favorites_tray
      );
    };

    handleSubTargetSelected = (ev, el) => {
      this.setState({
        currentImportTarget: el
      });
    };

    addItemToImportTargets = (
      id,
      name,
      toTheFront = false,
      selectItem = false
    ) => {
      let importTargets;
      const newItem = { id: id.toString(), name };
      if (toTheFront) {
        importTargets = [newItem, ...this.state.importTargets];
      } else {
        importTargets = [...this.state.importTargets, newItem];
      }

      this.setState({
        importTargets,
        currentImportTarget: selectItem
          ? { value: newItem.id, label: newItem.name }
          : this.state.currentImportTarget
      });
    };

    render () {
      const { importTargets, currentImportTarget } = this.state;
      const targetHandlerProps = {
        importTargets,
        currentImportTarget,
        handleSubTargetSelected: this.handleSubTargetSelected,
        addItemToImportTargets: this.addItemToImportTargets,
        isCanvasResourceSelectAvailable: this.isCanvasResourceSelectAvailable
      };
      return <WrappedComponent {...this.props} {...targetHandlerProps} />;
    }
  };
}
