import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsActions from '../../../actions/analytics';
import formatMessage from 'format-message';
import usGrades from './grade-schemes/us';

// actions
import {
  RouterActions,
  ResourceActions,
  SessionActions
} from '../../../actions';

// stores
import SessionStore from '../../../stores/session';

// components
import ResourceForm from './form';
import { getState } from '../../../store';

export default class ShareForm extends React.Component {
  static displayName = 'ShareForm'

  static propTypes = {
    account: PropTypes.object,
    licenses: PropTypes.object,
    session: PropTypes.object,
    consortiums: PropTypes.object,
    groups: PropTypes.array,
    resource: PropTypes.object,
    images: PropTypes.object,
    outcomes: PropTypes.object,
    featureFlags: PropTypes.object,
    search: PropTypes.object,
    FormComponent: PropTypes.func
  }

  static defaultProps = {
    FormComponent: ResourceForm
  }

  constructor (props, context) {
    super(props, context);
    this.defaultLearningObject = this.defaultLearningObject.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateSelectionChanged = this.updateSelectionChanged.bind(this);

    this.state = {
      thumbnail: {},
      isSubmitting: true,
      isUpdate: !!(
        props.session.session.canUpdate &&
        props.session.session.updateResourceId
      )
    };
  }

  UNSAFE_componentWillMount () {
    this.defaultLearningObject();
  }

  defaultLearningObject () {
    var update = {
      title: this.props.session.session.resourceTitle || '',
      type: this.props.session.session.contextType,
      licenseIds: ['copyright_reserve'],
      licenseSpecs: '',
      gradeIds: usGrades.map(({ value }) => value),
      account: this.props.session.account,
      authors: [
        {
          id: this.props.session.user.id,
          name: this.props.session.user.fullName,
          avatarUrl: this.props.session.user.avatarUrl
        }
      ],
      scopeIds: [],
      createUserId: this.props.session.user.id,
      mediaObjectId: this.props.session.session.mediaObjectId,
      export: SessionStore.getExportData()
    };

    if (this.props.session.session.contextType === 'image') {
      update.thumbnail = { url: this.props.session.contentItemUrl };
    }

    ResourceActions.clearStore();
    ResourceActions.updateLocalLearningObject(update);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    var state = {};

    // When we get the first thumbnail we need to remember it so that if the user selects an update
    // it won't get overwritten for videos and images
    if (
      !this.state.thumbnail.url &&
      nextProps.resource.learningObject.thumbnail &&
      nextProps.resource.learningObject.thumbnail.url != null
    ) {
      state.thumbnail = nextProps.resource.learningObject.thumbnail;
    }

    if (
      nextProps.resource.findSuccessful &&
      nextProps.resource.learningObject.id !==
        this.props.resource.learningObject.id
    ) {
      const resourceUpdate = {};
      if (
        ['video', 'image'].indexOf(nextProps.resource.learningObject.type) > -1 &&
        this.state.thumbnail.url &&
        (!nextProps.resource.learningObject.thumbnail ||
          nextProps.resource.learningObject.thumbnail.url !== this.state.thumbnail.url)
      ) {
        resourceUpdate.thumbnail = this.state.thumbnail;
      }

      if (
        ['video', 'audio'].indexOf(nextProps.resource.learningObject.type) > -1
      ) {
        resourceUpdate.mediaObjectId = this.props.session.session.mediaObjectId;
      }

      resourceUpdate.export = SessionStore.getExportData();
      ResourceActions.updateLocalLearningObject(resourceUpdate);
    }

    this.setState(state);
  }

  updateSelectionChanged (data) {
    if (Object.prototype.hasOwnProperty.call(data, 'isUpdate')) {
      this.setState({
        isUpdate: data.isUpdate
      });
    }
    if (data.option) {
      ResourceActions.find(data.option.id);
    } else {
      if (this.props.resource.findPending) {
        ResourceActions.abortFind();
      }
      this.defaultLearningObject();
    }
  }

  handleSubmit () {
    const resource = this.props.resource.learningObject;
    const isUpdate = this.state.isUpdate;
    const isCurator =
      this.props.session.isAdmin || this.props.session.isAccountCurator;
    const account = this.props.account;
    const allowApprovedContent =
      account.settings && account.settings.allowApprovedContent;

    const newShareWorkflow = getState().featureFlags.flags.new_share_workflow;
    if (isUpdate) {
      ResourceActions.updateWithNewVersion(
        resource,
        isCurator,
        allowApprovedContent,
        newShareWorkflow
      );
    } else {
      ResourceActions.createNew(resource, newShareWorkflow);
    }
    AnalyticsActions.handleShareActions(isUpdate);
  }

  handleCancel (event) {
    ResourceActions.clearStore();
    SessionActions.cancelShare();
    RouterActions.transitionTo('/search');
  }

  render () {
    const buttonText = this.state.isUpdate
      ? formatMessage('Update')
      : formatMessage('Share');
    const isSubmitting =
      this.props.resource.createPending ||
      this.props.resource.updateWithNewVersionPending;
    const newVersionUpdateErrors = this.props.resource
      .updateWithNewVersionErrors;

    return (
      <this.props.FormComponent
        handleSubmit={this.handleSubmit}
        cancel={this.handleCancel}
        preventSubmission={isSubmitting}
        newVersionUpdateErrors={newVersionUpdateErrors}
        showUpdateOptions={this.props.session.session.canUpdate}
        isUpdate={this.state.isUpdate}
        updateSelection={this.state.option}
        updateSelectionChanged={this.updateSelectionChanged}
        submitButtonText={buttonText}
        contentItemUrl={this.props.session.contentItemUrl}
        sessionAccount={this.props.session.account}
        user={this.props.session.user}
        images={this.props.images}
        session={this.props.session}
        licenses={this.props.licenses}
        consortiums={this.props.consortiums}
        account={this.props.account}
        groups={this.props.groups}
        outcomes={this.props.outcomes}
        resource={this.props.resource.learningObject}
        formChanged={ResourceActions.updateLocalLearningObject}
        errors={
          this.state.isUpdate
            ? this.props.resource.updateWithNewVersionErrors
            : this.props.resource.createErrors
        }
        search={this.props.search}
        thumbnailRequired={!this.props.featureFlags.flags.placeholder_thumbnail}
      />
    );
  }
}
