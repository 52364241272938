import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Checkbox } from '@instructure/ui-checkbox';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';

import { confirmationDialog } from '../../confirmation-dialog';
import Link from '../../common/link';
import Trash from '../../common/trash';

export default createReactClass({
  displayName: 'GroupMember',

  propTypes: {
    changeManagementStatus: PropTypes.func.isRequired,
    deleteMember: PropTypes.func.isRequired,
    member: PropTypes.object.isRequired,
    groupName: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  },

  handleManagerChange (event) {
    const { member, changeManagementStatus } = this.props;
    changeManagementStatus({
      id: member.id,
      groupId: member.groupId,
      isManager: event.target.checked
    });
  },

  async confirmRemove () {
    const { member, deleteMember, groupName } = this.props;
    const message = formatMessage('You will remove {userName} from {name}.',
      {
        userName: member.userName,
        name: groupName
      });
    const title = formatMessage('Remove {userName}?', { userName: member.userName });

    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Remove')
    });

    if (confirmationAnswer) {
      deleteMember(member);
      this.shiftFocus();
    }
  },

  shiftFocus () {
    const memberElement = this.refs.tr;
    let elementToFocus = null;
    if (memberElement.previousSibling) {
      elementToFocus = memberElement.previousSibling.querySelector(
        '.GroupMember-cell.GroupMemberList-name-column'
      );
    }
    if (!elementToFocus) {
      elementToFocus = document.querySelector(
        'input[class=GroupMemberCreate-input]'
      );
    }

    if (elementToFocus) {
      elementToFocus.focus();
    }
  },

  render () {
    const { member, disabled } = this.props;

    return (
      <tr className="GroupMember" ref="tr">
        <td className="GroupMember-cell GroupMemberList-manager-column">
          <Checkbox
            checked={member.isManager}
            disabled={disabled}
            label={
              <ScreenReaderContent>
                {formatMessage('Set {userName} as manager', {
                  userName: member.userName
                })}
              </ScreenReaderContent>
            }
            onChange={disabled ? null : this.handleManagerChange}
            size="small"
            variant="toggle"
          />
        </td>
        <td className="GroupMember-cell GroupMemberList-name-column">
          {member.userName}
        </td>
        <td className="GroupMember-cell GroupMemberList-email-column">
          {member.userEmail}
        </td>
        <td className="GroupMember-cell GroupMemberList-delete-column">
          <Link
            className="GroupMember-delete-button"
            interaction={disabled ? Link.interaction.disabled : Link.interaction.enabled}
            onClick={disabled ? null : this.confirmRemove}
          >
            <span className="screenreader-only">
              {formatMessage('Remove {userName} from group', {
                userName: member.userName
              })}
            </span>
            <Trash />
          </Link>
        </td>
      </tr>
    );
  }
});
