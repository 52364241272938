import Uniflow from 'uniflow';
import LearningObjectActions from '../actions/resource';
import SearchActions from '../actions/search';

const emptyList = [];

const ResultsStore = Uniflow.createStore({
  get () {
    return this.state.items.filter(this.isNotDenylisted);
  },

  isNotDenylisted ({ id }) {
    return !~this.state.denylist.indexOf(id);
  },

  showLoading () {
    return this.state.loading && !this.get().length;
  },

  showEmpty () {
    return (
      !this.showLoading() &&
      !this.state.hasMore &&
      this.get().length === 0 &&
      !this.state.error
    );
  },

  handleDestroy (learningObjectId) {
    this.setState({
      denylist: [...this.state.denylist, learningObjectId],
      count: (this.state.count - 1) || 0,
      total: (this.state.total - 1) || 0
    });
  },

  handleRemoveFromFavorites (learningObjectId) {
    this.setState({
      items: this.state.items.filter(({ id }) => id !== learningObjectId),
      count: (this.state.count - 1) || 0,
      total: (this.state.total - 1) || 0
    });
  },

  handleReset () {
    if (this.state.pendingReq && this.state.pendingReq.xhr) {
      this.state.pendingReq.abort();
    }
    this.setState({
      items: emptyList,
      pendingReq: null,
      hasMore: true,
      nextCursor: null,
      count: null,
      total: null,
      searchSuccessful: false,
      loading: false
    });
  },

  handleRequest (req) {
    this.setState({
      pendingReq: req,
      error: null,
      searchSuccessful: false,
      loading: true
    });
    // avoid triggering loading animation for fast responses (i.e. cached)
    setTimeout(() => {
      if (this.state.pendingReq === req) {
        this.setState({ loading: true });
      }
    }, 15);
  },

  handleResponse (req, res) {
    if (this.state.pendingReq !== req) {
      return;
    }
    const body = { meta: {}, items: [], ...res.body };

    this.setState({
      items: [...this.state.items, ...body.items],
      nextCursor: body.meta.cursor ? JSON.stringify(body.meta.cursor) : null,
      count: body.meta.count || 0,
      total: body.meta.total || 0,
      pendingReq: null,
      loading: false,
      hasMore: !!body.meta.cursor,
      searchSuccessful: true
    });
  },

  handleRequestError (err) {
    this.setState({
      error: err,
      loading: false,
      hasMore: false
    });
  }
});

// Initial State
export function setInitialState () {
  ResultsStore.replaceState({
    items: emptyList,
    loading: false,
    pendingReq: null,
    denylist: emptyList,
    nextCursor: null,
    count: null,
    total: null,
    error: null,
    hasMore: true,
    searchSuccessful: false
  });
}
setInitialState();

// Subscribe to actions
LearningObjectActions.on(
  'learning-object-destroyed',
  ResultsStore.handleDestroy
);
SearchActions.on('results-reset', ResultsStore.handleReset);
SearchActions.on('results-requested', ResultsStore.handleRequest);
SearchActions.on('results-response', ResultsStore.handleResponse);
SearchActions.on('results-error', ResultsStore.handleRequestError);

export default ResultsStore;
