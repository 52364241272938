import React from 'react';
import PropTypes from 'prop-types';

// Actions
import SearchActions from '../../actions/search';

// Components
import SearchStats from './search-stats';
import FilterPills from './filter-pills';
import SearchControls from './search-controls';

export default class SearchInfo extends React.Component {
  static propTypes = {
    results: PropTypes.object,
    statsMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    consortiums: PropTypes.object,
    groups: PropTypes.object,
    router: PropTypes.object
  }

  hasFilterPills () {
    if (!this.props.router || !this.props.router.query) {
      return false;
    }

    return this.props.router.query.authorIds ||
      this.props.router.query.authorName ||
      (this.props.router.query.accountId && this.props.router.query.accountName) ||
      this.props.router.query.contentCategoryTypes ||
      this.props.router.query.gradeIds ||
      this.props.router.query.types ||
      (this.props.router.query.scopeIds && /(group-|consortium-|:)/.test(this.props.router.query.scopeIds));
  }

  hasSearchStats () {
    return (this.props.results.count || this.props.statsMessage) && !this.props.results.store.showLoading();
  }

  render () {
    return (
      <div className="SearchInfo">
        {this.hasFilterPills() && (
          <React.Fragment>
            <div className="SearchInfo-block">
              <SearchControls
                query={this.props.router.query}
              />
            </div>
            <div className="SearchInfo-block">
              <FilterPills
                query={this.props.router.query}
                account={this.props.session.account}
                consortiums={this.props.consortiums}
                groups={this.props.groups}
                canShowPublic={this.props.canShowPublic}
                onClose={SearchActions.update}
              />
            </div>
          </React.Fragment>
        )}
        {this.hasSearchStats() && (
          <div className="SearchInfo-block Results-count">
            <SearchStats
              isLoading={this.props.results.store.showLoading()}
              count={this.props.results.count}
              message={this.props.statsMessage}
            />
          </div>
        )}
      </div>
    );
  }
}
