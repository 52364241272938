import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';

export const CHANGE_ACCOUNT_SETTINGS = 'change-account-settings';
export const CLEAR_SAVE_STATE = 'clear-save-state';
export const LOAD_CURATOR_START = 'load-curator-start';
export const LOAD_CURATOR_SUCCESS = 'load-curator-success';
export const LOAD_CURATOR_FAILURE = 'load-curator-failure';
export const SAVE_ACCOUNT_SETTINGS_PENDING = 'save-account-settings-pending';
export const SAVE_ACCOUNT_SETTINGS_ERROR = 'save-account-settings-error';
export const SAVE_ACCOUNT_SETTINGS_SUCCESS = 'save-account-settings-successful';
export const SET_ACCOUNT_CURATORS_SUCCESS = 'set-account-curators-successful';
export const SAVE_ACCOUNT_CURATED_BANNER_IMAGE =
  'save-account-curated-banner-image';

export const creators = {
  changeAccountSettings: values => ({ type: CHANGE_ACCOUNT_SETTINGS, values }),
  clearSaveState: () => ({ type: CLEAR_SAVE_STATE }),
  loadCuratorStart: () => ({ type: LOAD_CURATOR_START }),
  loadCuratorSuccess: settings => ({
    type: LOAD_CURATOR_SUCCESS,
    curators: settings.curators || []
  }),
  loadCuratorFailure: error => ({ type: LOAD_CURATOR_FAILURE, error }),
  saveAccountSettingsPending: () => ({ type: SAVE_ACCOUNT_SETTINGS_PENDING }),
  saveAccountSettingsErrors: error => ({
    type: SAVE_ACCOUNT_SETTINGS_ERROR,
    error
  }),
  saveAccountSettingsSuccessful: (settings) => ({
    type: SAVE_ACCOUNT_SETTINGS_SUCCESS,
    settings
  }),
  setAccountCuratorsSuccessful: ({ curators }) => ({
    type: SET_ACCOUNT_CURATORS_SUCCESS,
    curators
  }),
  saveAccountCuratedBannerImage: image => ({
    type: SAVE_ACCOUNT_CURATED_BANNER_IMAGE,
    image
  })
};

export default function createAccountActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async loadAccountCurators () {
      actions.loadCuratorStart();
      const request = apiRequest({
        method: 'get',
        path: '/account/settings'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }

        actions.loadCuratorSuccess(response.body);
      } catch (err) {
        actions.loadCuratorFailure(err);
      }
    },

    async addAccouuntCurators (userId) {
      const body = {
        userId
      };
      actions.saveAccountSettingsPending();
      const request = apiRequest({
        method: 'post',
        path: '/account/settings/curators',
        body
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        if (response.body) {
          actions.setAccountCuratorsSuccessful(response.body);
        } else {
          actions.saveAccountSettingsSuccessful();
        }
      } catch (err) {
        actions.saveAccountSettingsErrors(err);
      }
    },

    async removeAccouuntCurators (userId) {
      actions.saveAccountSettingsPending();
      const request = apiRequest({
        method: 'delete',
        path: `/account/settings/curators/${userId}`
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.setAccountCuratorsSuccessful(response.body);
      } catch (err) {
        actions.saveAccountSettingsErrors(err);
      }
    },

    async uploadBrandingImage (image) {
      if (!(image instanceof window.Blob)) {
        return false;
      }

      actions.saveAccountSettingsPending();

      try {
        const body = new FormData();
        body.append('thumbnail', image, image.name);

        const req = apiRequest({
          method: 'post',
          path: '/account/settings/branding',
          contentType: null,
          body
        });

        const response = await req.promise();

        if (response && response.body.errors) {
          actions.saveAccountSettingsErrors(response.body.errors);
          return;
        }

        actions.saveAccountCuratedBannerImage(
          response.body.accountCuratedBannerImage
        );
      } catch (err) {
        actions.saveAccountSettingsErrors(err);
      }
    },

    async resetBrandingImage () {
      actions.saveAccountSettingsPending();
      try {
        const request = apiRequest({
          method: 'delete',
          path: '/account/settings/branding'
        });

        const response = await request.promise();
        if (response && response.body.errors) {
          actions.saveAccountSettingsErrors(response.body.errors);
          return;
        }
        actions.saveAccountCuratedBannerImage(null);
      } catch (err) {
        actions.saveAccountSettingsErrors(err);
      }
    },

    async saveAccountSettings (settings) {
      actions.saveAccountSettingsPending();

      const { publicOnly, ...accountSettings } = settings; // eslint-disable-line no-unused-vars
      if (!accountSettings.showStateStandards) {
        accountSettings.stateStandard = 'NONE';
      }

      const { curators, ...body } = accountSettings;

      const request = apiRequest({
        method: 'post',
        path: '/account/settings',
        body
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.saveAccountSettingsSuccessful(response.body);
      } catch (err) {
        actions.saveAccountSettingsErrors(err);
      }
    }
  };
}
