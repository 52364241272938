import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Tabs } from '@instructure/ui-tabs/lib';
import { ApplyTheme } from '@instructure/ui-themeable';
import isEqual from 'lodash/lang/isEqual';
import router from '../../router';
import { getState } from '../../store';

export default class AdminTabs extends React.Component {
  static defaultProps = {
    isManager: false,
    statsFlag: false
  }

  static propTypes = {
    activeTab: PropTypes.oneOf([
      'groups',
      'resources',
      'settings',
      'consortiums',
      'stats'
    ]).isRequired,
    session: PropTypes.object.isRequired,
    isManager: PropTypes.bool,
    statsFlag: PropTypes.bool
  }

  shouldComponentUpdate (nextProps) {
    return !isEqual(this.props, nextProps);
  }

  onTabChange = async (_, { index }) => {
    const {
      session: { publicOnly, isAdmin = false },
      isManager,
      statsFlag
    } = this.props;

    const showGroups = !publicOnly && (isManager || isAdmin);
    const showConsortiums = !publicOnly && isAdmin;
    const showAccountSettings = isAdmin;
    const showStats = statsFlag || isAdmin;

    const tabRoutes = [];

    showAccountSettings && tabRoutes.push('/account/settings');
    showGroups && tabRoutes.push('/account/groups');
    showConsortiums && tabRoutes.push('/account/consortiums');
    tabRoutes.push('/account/resources');
    showStats && tabRoutes.push('/account/stats');

    await router.navigate(tabRoutes[index]);
  }

  render () {
    const state = getState();
    const useICEEMenus = state.featureFlags.flags.use_icee_menus;
    const dontUseICEEMenus = state.featureFlags.flags.dont_use_icee_menus;

    if (useICEEMenus && !dontUseICEEMenus) {
      return null;
    }

    const {
      activeTab,
      session: { publicOnly, isAdmin = false },
      isManager,
      statsFlag
    } = this.props;

    const showGroups = !publicOnly && (isManager || isAdmin);
    const showConsortiums = !publicOnly && isAdmin;
    const showAccountSettings = isAdmin;
    const showStats = statsFlag || isAdmin;

    const tabPanels = [];

    if (showAccountSettings) {
      tabPanels.push(
        <Tabs.Panel key="settings" isSelected={activeTab === 'settings' || !activeTab} renderTitle={formatMessage('Account Settings')}></Tabs.Panel>
      );
    }

    if (showGroups) {
      tabPanels.push(<Tabs.Panel key="groups" isSelected={activeTab === 'groups'} renderTitle={formatMessage('Groups')}> </Tabs.Panel>);
    }

    if (showConsortiums) {
      tabPanels.push(
        <Tabs.Panel key="consortiums" isSelected={activeTab === 'consortiums'} renderTitle={formatMessage('Consortiums')}>
        </Tabs.Panel>
      );
    }

    tabPanels.push(
      <Tabs.Panel key="resources" isSelected={activeTab === 'resources'} renderTitle={formatMessage('Managed Resources')}>
      </Tabs.Panel>
    );

    if (showStats) {
      tabPanels.push(
        <Tabs.Panel key="stats" isSelected={activeTab === 'stats'} renderTitle={formatMessage('Stats')}>
        </Tabs.Panel>
      );
    }
    if (tabPanels.length === 0) {
      return <div />;
    }
    return (
      <ApplyTheme theme={{
        [Tabs.Tab.theme]: {
          defaultSelectedBorderColor: '#0374B5',
        }
      }}>
        <Tabs
          onRequestTabChange={this.onTabChange}
          shouldFocusOnRender
        >
          {tabPanels}
        </Tabs>
      </ApplyTheme>
    );
  }
}
