import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';
import IconX from '@instructure/ui-icons/lib/IconXLine';

import Pill from '../common/pill';

export default createReactClass({
  displayName: 'FilterPill',

  propTypes: {
    filterParam: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
  },

  onClick () {
    this.props.onClose(
      this.props.filterParam.param,
      this.props.filterParam.value
    );
  },

  render () {
    const filterParam = this.props.filterParam;
    return (
      <Pill className="FilterPills-button" onClick={this.onClick}>
        <span>{filterParam.alias}</span>
        <ScreenReaderContent>
          {formatMessage('Remove filter')}
        </ScreenReaderContent>
        <IconX />
      </Pill>
    );
  }
});
