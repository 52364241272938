import React from 'react';
import Head from 'react-helmet';
import {
  ConsortiumActions,
  AccountsActions,
  SessionActions
} from '../../actions';
import AppHandler from '../../components/app-handler';
import AccountConsortium from '../../components/account/consortium';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import AccountHeader from './account-header';

/* eslint-disable react/prop-types */
const view = () => {
  const state = getState();
  if (!state.consortium.consortiumIsLoading) {
    const consortiumRoot = state.consortium;
    const isAllowed =
      !state.session.retrievalSuccessful ||
      (consortiumRoot.consortium &&
        state.session.account.id === consortiumRoot.consortium.creatorAccountId);
    if (!isAllowed) {
      return <NotAuthenticated />;
    }
  }

  return (
    <AppHandler
      activeHeader="account"
      session={state.session}
      updates={state.updates}
      logout={SessionActions.logout}
    >
      <Head
        title={state.consortium.consortium && state.consortium.consortium.name}
      />
      <div className="AccountSettings App-main-inner-main-content-width">
        <AccountHeader />
        <AdminTabs
          activeTab="consortiums"
          session={state.session}
          isManager={!!state.groups.isManager}
        />
        <AccountConsortium
          actions={{ ...ConsortiumActions, ...AccountsActions }}
          state={{ ...state.consortium, ...state.accounts }}
          session={state.session}
        />
      </div>
    </AppHandler>
  );
};

export default function ({ params, resolve, render, exiting }) {
  const consortiumId = params.consortiumId;
  ConsortiumActions.reset();
  ConsortiumActions.loadConsortium(consortiumId);
  render(view());
  resolve();
  const unsubscribe = subscribe(() => render(view(consortiumId)));
  exiting.then(unsubscribe);
}
