import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import IconStarLight from '@instructure/ui-icons/lib/IconStarLightSolid';
import IconStar from '@instructure/ui-icons/lib/IconStarSolid';

import { FavoritesActions } from '../../actions';
import Button from '../common/button';

export default class ToggleFavoriteButton extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired,
    isToggling: PropTypes.bool
  }

  onClick = async event => {
    if (this.props.isToggling) {
      return;
    }
    if (this.props.learningObject.isFavorited) {
      await FavoritesActions.removeFromFavorites(this.props.learningObject);
    } else {
      await FavoritesActions.addAsFavorite(this.props.learningObject);
    }
  }

  render () {
    const isFavorited = this.props.learningObject.isFavorited;
    return (
      <div className="lor-course-toggle-favorite-btn">
        <Button
          renderIcon={isFavorited ? IconStar : IconStarLight}
          onClick={this.onClick}
          display={Button.display.block}
          textAlign={Button.textAlign.start}
        >
          {isFavorited
            ? formatMessage('Remove from Favorites')
            : formatMessage('Add to Favorites')}
        </Button>
      </div>
    );
  }
}
