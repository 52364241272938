import React from 'react';
import RedText from './layouts/red-text';
import Link from '../common/link';
import ComplexMessage from '../common/complex-message';
import ResultPathStore from '../../stores/result-path';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';

export default function NotAuthenticated () {
  const message = formatMessage('Sorry, you are not authorized to view this page.');
  announce.assertively(message);
  return (
    <RedText>
      <h1 className="screenreader-only">{message}</h1>
      <ComplexMessage>
        {message + ' ' + formatMessage('{back} to go back', {
          back: ComplexMessage.placeholder('back')
        })}
        <Link
          className="NotAuthenticated-back"
          key="back"
          href={`${ResultPathStore.state.pathname}${ResultPathStore.state.search}`}>
          {formatMessage('Click here')}
        </Link>
      </ComplexMessage>
    </RedText>
  );
}
