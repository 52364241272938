import { LOAD_SESSION_SUCCESS } from '../actions/session';
import {
  RESET,
  SEARCH_STANDARDS_START,
  SEARCH_STANDARDS_CANCEL,
  SEARCH_STANDARDS_SUCCESS,
  SEARCH_STANDARDS_FAILURE,
  SET_SHOW_FEDERAL_STANDARDS,
  SET_STATE_AUTHORITY,
  SET_SHOW_STATE_STANDARDS
} from '../actions/outcomes';

import Error from './error-record';

export const initialState = {
  userId: null,
  showFederalStandards: true,
  showStateStandards: true,
  stateStandard: 'NONE',

  query: '',
  results: [],
  searchPending: false,
  searchError: null,
  searchSuccessful: false,
  count: 0,
  limit: 0,
  offset: 0
};

export default function outcomesReducer (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;

    case LOAD_SESSION_SUCCESS:
    {
      const { userId } = action.session;
      return {
        ...state,
        userId,
        showFederalStandards: window.localStorage[`${userId}-show-federal`] !== 'false',
        showStateStandards: window.localStorage[`${userId}-show-state`] !== 'false',
        stateStandard: window.localStorage[`${userId}-state`] || action.session.stateStandard
      };
    }

    case SET_SHOW_FEDERAL_STANDARDS:
      window.localStorage[`${state.userId}-show-federal`] = action.value ? 'true' : 'false';
      return {
        ...state,
        showFederalStandards: Boolean(action.value)
      };

    case SET_SHOW_STATE_STANDARDS:
      window.localStorage[`${state.userId}-show-state`] = action.value ? 'true' : 'false';
      return {
        ...state,
        showStateStandards: Boolean(action.value)
      };

    case SET_STATE_AUTHORITY:
      window.localStorage[`${state.userId}-state`] = action.value;
      return {
        ...state,
        stateStandard: action.value
      };

    case SEARCH_STANDARDS_START:
      return {
        ...state,
        query: action.query,
        searchPending: true,
        searchSuccessful: false,
        searchError: null
      };

    case SEARCH_STANDARDS_CANCEL:
      return {
        ...state,
        query: '',
        results: [],
        searchPending: false,
        searchSuccessful: false,
        searchError: null
      };

    case SEARCH_STANDARDS_SUCCESS:
      return {
        ...state,
        searchPending: false,
        searchSuccessful: true,
        searchError: null,
        results: action.result.items
          .filter(({ guid, number }) => (guid && number)),
        count: action.result.meta.count,
        limit: action.result.meta.limit,
        offset: action.result.meta.offset
      };

    case SEARCH_STANDARDS_FAILURE:
      return {
        ...state,
        searchPending: false,
        searchSuccessful: false,
        searchError: new Error(action.error)
      };

    default:
      return state;
  }
}
