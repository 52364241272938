import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tokeninput, { Option as TokeninputOption } from 'react-tokeninput';

import * as announce from '../../common/announce';
import formatMessage from 'format-message';
import OutcomesNoResults from './outcomes-no-results';

import { OutcomesActions } from '../../actions';
import OutcomesResult from './outcomes-result';
import Warning from './warning';
import NotifyMessage from './notify-message';
import Loading from './loading';
import Checkbox from './checkbox';
import OutcomeAuthoritySelect from './outcome-authority-select';

export default class Outcomes extends React.Component {
  static propTypes = {
    outcomes: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
  }

  handleInput = userInput => {
    const account = this.props.account.settings;
    const {
      showFederalStandards,
      showStateStandards,
      stateStandard
    } = this.props.outcomes;
    OutcomesActions.search(
      userInput,
      account.showStateStandards && showStateStandards ? stateStandard : false,
      account.showFederalStandards && showFederalStandards
    );
  }

  handleSelect = selectedOutcome => {
    if (typeof selectedOutcome === 'string') {
      return;
    }

    const selected = this.props.value
      .filter(outcome => {
        return outcome.id !== selectedOutcome.guid;
      })
      .concat({
        id: selectedOutcome.guid,
        title: selectedOutcome.number
      });
    this.props.onChange(selected);
    announce.assertively(formatMessage('Outcome added'));
  }

  componentWillUnmount () {
    announce.reset();
  }

  handleRemove = outcomeToRemove => {
    const selectedOptions = this.props.value.filter(outcome => {
      return outcome.id !== outcomeToRemove.id;
    });
    this.props.onChange(selectedOptions);
    announce.assertively(formatMessage('Outcome removed'));
  }

  renderSearchResults () {
    let results = this.props.outcomes.results.filter(outcome => {
      // ensures that selected outcomes don't show in result set
      return this.props.value.every(selectedOutcome => {
        return selectedOutcome.id !== outcome.guid;
      });
    });

    if (results.length === 0 && this.props.outcomes.searchSuccessful) {
      return [<OutcomesNoResults key="0" />];
    }

    results = results.map(option => {
      return (
        <TokeninputOption
          className="outcome-result"
          key={option.guid}
          value={option}
        >
          <OutcomesResult option={option} />
        </TokeninputOption>
      );
    });

    return results;
  }

  handleShowFederalStandardsChange (evt) {
    OutcomesActions.setShowFederalStandards(evt.target.checked);
  }

  handleShowStateStandardsChange (evt) {
    OutcomesActions.setShowStateStandards(evt.target.checked);
  }

  handleStateAuthorityChange (evt) {
    OutcomesActions.setStateAuthority(evt.target.value);
  }

  render () {
    const isDisabled = this.props.isDisabled;
    const selectedOptions = this.props.value.map(outcome => {
      return {
        id: outcome.id,
        name: outcome.title
      };
    });

    const loadingComponent = <Loading />;
    const outcomesSearchPending = this.props.outcomes.searchPending;
    const {
      showFederalStandards,
      showStateStandards
    } = this.props.account.settings;

    if (this.props.outcomes.searchError) {
      return (
        <NotifyMessage
          kind="alert"
          className="outcome-warning"
          icon={<Warning width="20px" height="20px" />}
        >
          <span className="outcome-warning-main-text">
            {formatMessage('Outcomes are currently unavailable.')}
          </span>
          <span className="outcome-warning-helper-text">
            {formatMessage(
              'You can still share this resource and add outcomes when they become available later.'
            )}
          </span>
        </NotifyMessage>
      );
    }

    const authorityFieldClassnames = classnames('Outcomes-authority-field', {
      'Outcomes-authority-field--disabled': isDisabled
    });

    return (
      <div className="Outcomes">
        <div className="Outcomes-help-text">
          {formatMessage('Filter outcomes libraries:')}
        </div>
        {showFederalStandards && (
          <label className={authorityFieldClassnames}>
            <Checkbox
              checked={this.props.outcomes.showFederalStandards}
              className="Outcomes-authority-check"
              id="federalOutcomes"
              disabled={isDisabled}
              name="federalOutcomes"
              onChange={this.handleShowFederalStandardsChange}
              value="true"
            />
            {formatMessage('Common Core Standards')}
          </label>
        )}
        {showStateStandards && (
          <span className={authorityFieldClassnames}>
            <label className="screenreader-only" htmlFor="stateOutcomes">
              {formatMessage('State Outcomes')}
            </label>
            <Checkbox
              checked={this.props.outcomes.showStateStandards}
              className="Outcomes-authority-check"
              disabled={isDisabled}
              id="stateOutcomes"
              name="stateOutcomes"
              onChange={this.handleShowStateStandardsChange}
              value="true"
            />
            <label className="screenreader-only" htmlFor="stateAuthority">
              {formatMessage('Select State Standards')}
            </label>
            <OutcomeAuthoritySelect
              id="stateAuthority"
              disabled={isDisabled}
              name="stateAuthority"
              noneLabel={formatMessage('Select State Standards')}
              onChange={this.handleStateAuthorityChange}
              value={this.props.outcomes.stateStandard}
            />
          </span>
        )}
        <Tokeninput
          id={this.props.id}
          isDisabled={isDisabled}
          isLoading={outcomesSearchPending}
          loadingComponent={loadingComponent}
          menuContent={this.renderSearchResults()}
          name={this.props.name}
          onInput={this.handleInput}
          onRemove={this.handleRemove}
          onSelect={this.handleSelect}
          selected={selectedOptions}
          placeholder={
            selectedOptions.length > 0
              ? ''
              : formatMessage('Search for standards by keyword')
          }
        />
      </div>
    );
  }
}
