import React from 'react';
import formatMessage from 'format-message';
import CloseButton from '@instructure/ui-buttons/lib/CloseButton';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';
import FocusTrap from 'focus-trap-react';

import { AccountActions } from '../../actions';
import iconApprovedSrc from '../../../assets/svg/approved-check.svg';
import Message from '../common/message';
import ComplexMessage from '../common/complex-message';
import FilePicker from '../common/file-picker';
import * as announce from '../../common/announce';
import { DEFAULT_BRANDING_COLOR } from './branding-color-picker';
import Button from '../common/button';
import Link from '../common/link';

const IMG_WIDTH = 50;
const IMG_HEIGHT = 50;
const VALID_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.svg'];
const ESCAPE_KEY = 27;

export default class BrandingImageUpload extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      showModal: false,
      uploadError: false,
      showResetOption: !!props.accountCuratedBannerImage,
      color: props.accountCuratedBannerColor || DEFAULT_BRANDING_COLOR
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (
      nextProps.accountCuratedBannerColor &&
      nextProps.accountCuratedBannerColor !== this.state.color
    ) {
      this.setState(
        {
          color: nextProps.accountCuratedBannerColor
        },
        () => {
          this.updateButtonColor();
        }
      );
    }
  }

  onUpload = image => {
    const ext = `.${image.name
      .split('.')
      .pop()
      .toLowerCase()}`;

    const isValidExtension = VALID_EXTENSIONS.includes(ext);

    if (!isValidExtension) {
      this.uploadError(formatMessage('Please upload jpg, png, or svg files.'));
    } else {
      const img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);

        if (width === IMG_WIDTH && height === IMG_HEIGHT) {
          this.reattachModal();
          this.setState(
            {
              uploadError: null,
              showModal: false,
              showResetOption: true
            },
            () => {
              this.props.onChange(image);
            }
          );
        } else {
          this.uploadError(
            formatMessage(
              'Please upload an image of {width}x{height} pixels.',
              { width: IMG_WIDTH, height: IMG_HEIGHT }
            )
          );
        }
      };
    }
  }

  onKeyUp = event => {
    if (event.which === ESCAPE_KEY) {
      this.toggleModal();
    }
  }

  uploadError = error => {
    this.setState({ uploadError: error });
    announce.assertively(error);
  }

  toggleModal = () => {
    if (this.state.showModal) {
      this.reattachModal();
    } else {
      this.detachModal();
    }
    this.setState({ showModal: !this.state.showModal });
  }

  resetBranding = () => {
    AccountActions.resetBrandingImage();
    this.setState({ showResetOption: false });
  }

  // TODO: When upgraded to React 16, use a portal instead
  reattachModal = () => {
    if (
      this.state.showModal &&
      this.modal &&
      this.modal.parentNode !== this.container
    ) {
      this.container.append(this.modal);
      document.querySelector('#application').removeAttribute('aria-hidden');
    }
  }

  detachModal = () => {
    if (this.state.showModal && this.modal.parentNode !== document.body) {
      document.body.append(this.modal);
      document.querySelector('#application').setAttribute('aria-hidden', true);
    }
  }

  renderModal = () => {
    return (
      <div
        ref={this.setModalRef}
        className="lor-fullscreen-modal"
        aria-modal
        aria-label={formatMessage('Upload Image dialog')}
        aria-hidden={!this.state.showModal}
        role="dialog"
        onKeyUp={event => this.onKeyUp(event)}
      >
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true
          }}
          active={this.state.showModal}
        >
          <article className="lor-fullscreen-modal-page" tabIndex="-1">
            <div className="customBrandIcon-title">
              <h3>{formatMessage('Upload Image')}</h3>
              <div>
                <CloseButton
                  offset="none"
                  placement="end"
                  onClick={this.toggleModal}
                >
                  {formatMessage('Close')}
                </CloseButton>
              </div>
            </div>

            <div>
              {this.state.uploadError && (
                <Message type="error">
                  <strong>{this.state.uploadError}</strong>
                </Message>
              )}
              <FilePicker
                label={formatMessage(
                  'Drag and drop your image here or {browse} your computer.',
                  {
                    browse: ComplexMessage.placeholder('browse')
                  }
                )}
                typesLabel={formatMessage(
                  '50x50 pixels - jpg, png, or svg files'
                )}
                extensions={VALID_EXTENSIONS}
                onChange={this.onUpload}
              />
            </div>
          </article>
        </FocusTrap>
      </div>
    );
  }

  setButtonRef = button => {
    this.button = button;
    this.updateButtonColor();
  }

  setModalRef = div => {
    this.modal = div;
  }

  setContainerRef = div => {
    this.container = div;
  }

  updateButtonColor = () => {
    if (this.button) {
      this.button.classList.add('customBrandIcon-preview');
      this.button.querySelector('img').style.backgroundColor = this.state.color;
    }
  }

  render = () => {
    const icon = this.props.accountCuratedBannerImage || null;

    return (
      <React.Fragment>
        <div className="customBrandIcon-upload" ref={this.setContainerRef}>
          <Button
            elementRef={this.setButtonRef}
            className="customBrandIcon-upload-button"
            display={Button.display.block}
            textAlign={Button.textAlign.start}
            onClick={this.toggleModal}
          >
            <img
              src={icon || iconApprovedSrc}
              alt={formatMessage('Custom brand icon')}
            />
            <ScreenReaderContent>
              {formatMessage('Image preview')}
            </ScreenReaderContent>
          </Button>
          <Link onClick={this.toggleModal}>
            {formatMessage('Change image')}
          </Link>
          {this.state.showResetOption && (
            <React.Fragment>
              <span aria-hidden="true">-</span>
              <Link onClick={this.resetBranding}>
                {formatMessage('Reset to default')}
              </Link>
            </React.Fragment>
          )}
          {this.state.showModal && this.renderModal()}
        </div>
        <div className="customBrandIcon-subDescription">
          {formatMessage(
            'Size your image to 50 x 50 pixels. Accepted formats JPG, PNG and SVG.'
          )}
        </div>
      </React.Fragment>
    );
  }
}
