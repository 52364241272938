
import React from 'react';
import formatMessage from 'format-message';
import deadLaptop from '../../../assets/svg/dead-laptop.svg';

export default function DeadLaptop ({ children }) {
  return (
    <img
      alt={formatMessage('A dead laptop.')}
      src={deadLaptop}
    />
  );
}
