import React from 'react';
import './alert-footer.css';
import { Alert } from '@instructure/ui-alerts/lib';

export default function AlertFooter (props) {
  const { alerts } = props;
  const alertElements = alerts.map(
    ({ variant, timeout, onDismiss, id, message, isDismissNeeded }) => (
      <Alert
        key={id}
        variant={variant}
        timeout={timeout}
        onDismiss={onDismiss}
        renderCloseButtonLabel={isDismissNeeded && 'Close'}
      >
        {message}
      </Alert>
    )
  );
  return <div className="AlertFooter-container">{alertElements}</div>;
}
