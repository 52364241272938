import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Link } from '@instructure/ui-link/lib/Link';

import normalizeUri from '../../common/normalize-uri';
import {
  addAttributeByVariableToElement,
  addAttributesByPrefixToElement,
  addClassNamesToElement
} from '../../common/modify-elements';

const addAttributes = (link, isActive, className, rel, restOfProps, tabIndex, target) => {
  const ariaLabel = restOfProps['aria-label'] ? {
    'aria-label': isActive
      ? formatMessage('{ariaLabel} selected.', {
        ariaLabel: restOfProps['aria-label']
      })
      : restOfProps['aria-label'],
  } : {};
  const props = {
    ...restOfProps,
    ...ariaLabel,
    'aria-expanded': restOfProps['aria-expanded'] || 'undefined',
  };

  addClassNamesToElement(link, className, ['Link', {
    active: isActive
  }]);

  addAttributesByPrefixToElement(link, props, 'data-');
  addAttributesByPrefixToElement(link, props, 'aria-');

  addAttributeByVariableToElement(link, tabIndex, 'tabIndex');
  addAttributeByVariableToElement(link, target, 'target');
  addAttributeByVariableToElement(link, rel, 'rel');

  if (restOfProps.interaction && restOfProps.interaction !== CommonsLink.interaction.enabled) {
    link.setAttribute('aria-disabled', 'true');
  }

  return true;
};

const CommonsLink = forwardRef((props, ref) => {
  const { isActive, query, rel, tabIndex, ...restOfProps } = {
    isWithinText: false,
    className: '',
    ...props,
  };

  if (restOfProps.href) {
    restOfProps.href = normalizeUri(restOfProps.href, restOfProps.query);
  }

  const { className, elementRef, target } = restOfProps;
  const addAttributesOnElement = (link) => addAttributes(link, isActive, className, rel, restOfProps, tabIndex, target);

  return (
    <Link
      {...restOfProps}
      ref={ref}
      interaction={restOfProps.interaction}
      elementRef={link => link && addAttributesOnElement(link) && elementRef && elementRef(link)}
    >
      {restOfProps.children}
    </Link>
  );
});

CommonsLink.color = {
  link: 'link',
  linkInverse: 'link-inverse',
};

CommonsLink.display = {
  auto: 'auto',
  flex: 'flex',
  block: 'block',
  inlineFlex: 'inline-flex',
  inlineBlock: 'inline-block',
};

CommonsLink.interaction = {
  enabled: 'enabled',
  disabled: 'disabled',
};

CommonsLink.propTypes = {
  ref: PropTypes.func || PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isWithinText: PropTypes.bool,
  interaction: PropTypes.oneOf(Object.values(CommonsLink.interaction)),
  color: PropTypes.oneOf(Object.values(CommonsLink.color)),
  display: PropTypes.oneOf(Object.values(CommonsLink.display)),
  margin: PropTypes.string,
  query: PropTypes.object,
  children: PropTypes.node,
  elementRef: PropTypes.func,
  renderIcon: PropTypes.func || PropTypes.node,
  tabIndex: PropTypes.number,
  'data-automation': PropTypes.string,
  'aria-label': PropTypes.string,
  'data-heap-redact-text': PropTypes.string,
  'data-heap-redact-attributes': PropTypes.string,
};

export default CommonsLink;
