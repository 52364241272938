import { dispatch } from '../store';
import createAccountsActions from './accounts';
import createAccountActions from './account';
import createGroupActions from './group';
import createGroupsActions from './groups';
import createOutcomesActions from './outcomes';
import createConsortiumsActions from './consortiums';
import createConsortiumActions from './consortium';
import createUsersActions from './users';
import createFavoritesActions from './favorites';
import createFeatureFlagActions from './feature-flags';

import SessionActions, { creators as SessionActionCreators } from './session';

export { default as AnalyticsActions } from './account';
export { default as CoursesActions } from './courses';
export { default as ImageActions } from './images';
export { default as LicensesActions } from './licenses';
export { default as NotificationCenterActions } from './notification-center';
export { default as ResourceActions } from './resource';
export { default as RouterActions } from './router';
export { default as SearchActions } from './search';
export { default as SessionActions } from './session';
export { default as UpdatesActions } from './updates';
export { default as WecomeBannerActions } from './welcome-banner';

// allow session load action to be forwarded to redux reducers
// define here rather than in session.js to avoid circular dependency
SessionActions.loadSessionSuccess = session => {
  dispatch(SessionActionCreators.loadSessionSuccess(session));
};

export const AccountsActions = createAccountsActions(dispatch);
export const AccountActions = createAccountActions(dispatch);
export const GroupActions = createGroupActions(dispatch);
export const GroupsActions = createGroupsActions(dispatch);
export const OutcomesActions = createOutcomesActions(dispatch);
export const ConsortiumsActions = createConsortiumsActions(dispatch);
export const ConsortiumActions = createConsortiumActions(dispatch);
export const UsersActions = createUsersActions(dispatch);
export const FavoritesActions = createFavoritesActions(dispatch);
export const FeatureFlagActions = createFeatureFlagActions(dispatch);
