import React from 'react';
import PropTypes from 'prop-types';
import ResourceTypeIcon from '../icons/resource-type';
import { resourceTypes } from '../../common/app-constants';

import { Text } from '@instructure/ui-text/lib';
import { Flex } from '@instructure/ui-flex/lib';

export default class LearningObjectHeader extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object
  }

  static defaultProps = {
    learningObject: {}
  }

  render () {
    const { title, type } = this.props.learningObject;
    return (
      <header className="lor-course-header">
        <Flex>
          <Flex.Item padding="0 small 0 0" margin="0 small 0 0">
            <ResourceTypeIcon
              aria-label={resourceTypes[type].singularName}
              className="lor-course-title-icon"
              type={type || 'course'}
              colored
            />
          </Flex.Item>
          <Flex.Item>
            <div className="lor-course-title-container">
              <h1 className="lor-course-title">{title}</h1>
              <div className={`font-color type-${type}`}>
                <Text transform="uppercase" weight="bold" size="x-small">
                  {resourceTypes[type].singularName}
                </Text>
              </div>
            </div>
          </Flex.Item>
        </Flex>
      </header>
    );
  }
}
