import formatMessage from 'format-message';
import { TopNavBar } from '@instructure/ui-top-nav-bar';
import IconMoreSolid from '@instructure/ui-icons/lib/IconMoreSolid';
import { Drilldown } from '@instructure/ui-drilldown';
import { Avatar } from '@instructure/ui-avatar';
import { View } from '@instructure/ui-layout';
import { Menu } from '@instructure/ui-menu/lib/Menu';
import React from 'react';

import { getLocalizedUrl } from '../../common/localized-urls';

const getActionsMenu = ({
  isAuthenticated,
  help_tray,
  isSmallViewport,
}) => {
  const actions = [];
  const actionItems = [];

  if (!isAuthenticated) {
    actionItems.push(
      {
        id: 'community',
        additionalProps: {
          target: '_blank',
          href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons-Users/gh-p/commons'),
        },
        label: formatMessage('Community'),
      }
    );

    if (!help_tray) {
      actionItems.push(
        {
          id: 'about',
          additionalProps: {
            target: '_blank',
            href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons/What-is-Canvas-Commons/ta-p/1788'),
          },
          label: formatMessage('About'),
        }
      );
    }

    if (isSmallViewport) {
      actions.push(
        <TopNavBar.Item
          id="actions-more"
          key="actions-more"
          renderIcon={<IconMoreSolid size="x-small" />}
          showSubmenuChevron={false}
          customPopoverConfig={{
            on: 'click',
            placement: 'bottom end',
            shouldContainFocus: true,
            children: (
              <Menu>
                {
                  actionItems.map((item) => {
                    return (
                      <Menu.Item key={item.id} {...item.additionalProps}>
                        {item.label}
                      </Menu.Item>
                    );
                  })
                }
              </Menu>
            ),
          }}
        >
          More
        </TopNavBar.Item>
      );
    } else {
      return actionItems.map((item) => (
        <TopNavBar.Item
          id={item.id}
          key={item.id}
          {...item.additionalProps}
        >
          {item.label}
        </TopNavBar.Item>
      ));
    }
  }

  return actions;
};

const renderUser = ({
  isAuthenticated,
  isInFrame,
  isSmallViewport,
  userFullName,
  userAvatarUrl,
  retrievalSuccessful,
  logout
}) => {
  if (isInFrame && !isSmallViewport) {
    return undefined;
  }

  let content;
  if (isAuthenticated && retrievalSuccessful) {
    content = (
      <TopNavBar.Item
        id="avatar-menu"
        data-testid="AppHeaderAvatar"
        size="small"
        variant="default"
        showSubmenuChevron={false}
        renderSubmenu={(
          <Drilldown rootPageId="avatar">
            <Drilldown.Page id="avatar">
              <Drilldown.Option
                id="logout"
                key="logout"
                data-cid="userprofile-logout"
                data-heap-redact-text
                onOptionClick={logout}
              >
                {formatMessage('Log out {userFullName}', { userFullName })}
              </Drilldown.Option>
            </Drilldown.Page>
          </Drilldown>
        )}
      >
        <Avatar
          name={userFullName}
          src={userAvatarUrl}
          showBorder="always"
          size="x-small"
          alt={formatMessage("{name}'s avatar, click to open menu", { name: userFullName })}
          data-testid="AppHeaderAvatar"
        />
        {isSmallViewport && <View margin="0 0 0 small">{userFullName}</View>}
      </TopNavBar.Item>
    );
  } else if (!isAuthenticated && !isInFrame) {
    content = (
      <TopNavBar.Item id="login" key="login" href="/login">
        {formatMessage('Log in')}
      </TopNavBar.Item>
    );
  }

  return (
    content ? (
      <TopNavBar.User>
        {content}
      </TopNavBar.User>
    ) : undefined
  );
};

export {
  getActionsMenu,
  renderUser
};
