import Uniflow from 'uniflow';
import RouterActions from '../actions/router';

var ResultPathStore = Uniflow.createStore({
  state: {
    pathname: '/search',
    search: ''
  }
});

RouterActions.on('matchResult', ({ location }) => {
  ResultPathStore.setState({
    pathname: location.pathname,
    search: location.search
  });
});

export default ResultPathStore;
