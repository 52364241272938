import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import SessionStore from '../stores/session';
import LearningObjectStore from '../stores/resource';

export const RESET_FAVORITES = 'favorites.reset';
export const LOAD_LIST_START = 'favorites.load-list-start';
export const LOAD_LIST_SUCCESS = 'favorites.load-list-success';
export const LOAD_LIST_FAILURE = 'favorites.load-list-failure';
export const FILTER_LIST = 'favorites.filter-list';
export const ADD_FAVORITE_SUCCESS = 'favorites.add-favorite-success';
export const ADD_FAVORITE_FAILURE = 'favorites.add-favorite-failure';
export const TOGGLING_START = 'favorites.toggling-start';
export const REMOVE_SUCCESS = 'favorites.remove-success';
export const REMOVE_FAILURE = 'favorites.remove-failure';

export const creators = {
  reset: () => ({ type: RESET_FAVORITES }),
  loadListStart: () => ({ type: LOAD_LIST_START }),
  loadListSuccess: (list) => ({ type: LOAD_LIST_SUCCESS, list }),
  loadListFailure: (error) => ({ type: LOAD_LIST_FAILURE, error }),
  filterList: (filter) => ({ type: FILTER_LIST, filter }),
  addFavoriteSuccess: (learningObject) => ({ type: ADD_FAVORITE_SUCCESS, learningObject }),
  addFavoriteFailure: (err) => ({ type: ADD_FAVORITE_FAILURE, err }),
  togglingStart: () => ({ type: TOGGLING_START }),
  removeFromFavoriteSuccess: (learningObject) => ({ type: REMOVE_SUCCESS, learningObject }),
  removeFromFavoriteFailure: (err) => ({ type: REMOVE_FAILURE, err })
};

export default function createFavoritesActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async loadList (passive = false) {
      if (!passive) {
        actions.loadListStart();
      }
      const request = apiRequest({
        path: `/users/${SessionStore.state.session.userId}/favorites`
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.loadListSuccess(response.body.items);
        return response.body.items;
      } catch (err) {
        actions.loadListFailure(err);
      }
    },

    async addAsFavorite (learningObject) {
      actions.togglingStart();
      const request = apiRequest({
        path: `/users/${SessionStore.state.session.userId}/favorites`,
        method: 'post',
        body: {
          resourceId: learningObject.id
        }
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        LearningObjectStore.updateFavoriteCounterBy(1);
        actions.addFavoriteSuccess(learningObject);
        return learningObject;
      } catch (err) {
        actions.addFavoriteFailure(err);
      }
    },

    async removeFromFavorites (learningObject) {
      actions.togglingStart();
      const request = apiRequest({
        path: `/users/${SessionStore.state.session.userId}/favorites/${learningObject.id}`,
        method: 'delete'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        LearningObjectStore.updateFavoriteCounterBy(-1);
        actions.removeFromFavoriteSuccess(learningObject);
        return learningObject;
      } catch (err) {
        actions.removeFromFavoriteFailure(err);
      }
    }
  };
}
