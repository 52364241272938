import env from '../config/env';
import { SessionActions, CoursesActions, GroupsActions, FavoritesActions, RouterActions } from '../actions';
import { getState } from '../store';
import { heapInitializer } from '../common/analytics/heap';

import React from 'react';
import AppHandler from '../components/app-handler';
import LoadingSpinner from '../components/common/loading-spinner';

export default async function ({ path, render, resolve, router }) {
  if (RouterActions.isMaintenanceMode()) {
    return;
  }
  const sid = env.SESSION_ID;

  if (!sid) {
    heapInitializer();
    return;
  }

  let { session, updates, groups } = getState();
  if (!session.retrievalSuccessful && !session.retrievalPending) {
    render(<LoadingSpinner />);

    await (router.sessionLoading = SessionActions.getCurrentSession(sid));
    session = getState().session;
    if (session.retrievalError) {
      RouterActions.replaceWith('/search');
      return;
    }

    render(<AppHandler session={session} updates={updates} isManager={groups.isManager} logout={SessionActions.logout} />);
    if (!session.limited) {
      CoursesActions.getCourses();
      GroupsActions.loadList();
      FavoritesActions.loadList();
    }
  }

  if (session.authRedirectUrl != null) {
    render(<AppHandler session={session} updates={updates} logout={SessionActions.logout} />);
    window.location.replace(session.authRedirectUrl);
    return resolve();
  }
}
