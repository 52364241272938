import React from 'react';
import PropTypes from 'prop-types';
import config from '../../common/app-constants';
import YellowOops from './layouts/yellow-oops';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';

function getMsgForCode (code) {
  var msg = config.lti.badConfigMsgs[code];
  if (msg) {
    return (
      <div className="lor-warning-details">
        <p className="lor-warning-message">{msg}</p>
        <p className="lor-warning-code">
          {formatMessage('code: {code}', { code })}
        </p>
      </div>
    );
  }
}

export default function BadConfig400 ({ code }) {
  const message = formatMessage(
    'Sorry, there is a problem in your Canvas/Commons configuration.'
  );
  announce.assertively(message);
  return (
    <YellowOops>
      <h1 className="screenreader-only">
        {message}
      </h1>
      <h2 className="lor-warning-title">{message}</h2>
      {getMsgForCode(code)}
      <p>{formatMessage('Please notify your Canvas administrator.')}</p>
    </YellowOops>
  );
}

BadConfig400.propTypes = {
  code: PropTypes.string
};
