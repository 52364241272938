import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

export default class SearchStats extends React.Component {
  getMessage () {
    const { isLoading, count } = this.props;

    let message;

    if (isLoading) {
      message = formatMessage('Results loading...');
    } else if (count) {
      message = formatMessage(
        `{
          count, plural,
          one {1 result}
          other {# results}
        }`,
        { count }
      );
    }
    return this.props.message || message;
  }

  render () {
    return (
      <div className="Search-results-total" aria-live="polite" role="status">
        {this.getMessage()}
      </div>
    );
  }
}

SearchStats.displayName = 'SearchStats';
SearchStats.propTypes = {
  message: PropTypes.node,
  isLoading: PropTypes.bool,
  count: PropTypes.number
};
