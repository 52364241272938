import React from 'react';
import Link from '../../common/link';
import formatMessage from 'format-message';
import notFoundSrc from '../../../../assets/svg/not-found.svg';
import * as announce from '../../../common/announce';

export default function Green404 ({ children }) {
  const message = formatMessage('Error 404: sorry, this page or resource does not seem to exist.');
  announce.assertively(message);
  return (
    <div className="lor-error-layout">
      <h1 className="screenreader-only">{message}</h1>
      {children}
      <img
        alt={message}
        src={notFoundSrc}
      />
      <Link href="/search">
        {formatMessage('Go to Search')}
      </Link>
    </div>
  );
}
