export function displayAsApprovedContent ({
  allowApprovedContent,
  scopeIds,
  accountId,
  learningObjectAccountId
}) {
  return (
    allowApprovedContent &&
    scopeIds.some(scopeId => scopeId.includes('curated-')) &&
    accountId === learningObjectAccountId
  );
}
