import Uniflow from 'uniflow';
import WelcomeBannerActions from '../actions/welcome-banner';
import RouterActions from '../actions/router';

const WelcomeBannerStore = Uniflow.createStore({
  state: {
    isShown: true
  }
});

WelcomeBannerActions.on('new-search', () => {
  WelcomeBannerStore.setState({ isShown: true });
});

RouterActions.on('match', (state) => {
  const query = state.location.query;
  const hasSearched = (
    state.location.pathname === '/search' && (
      query.q ||
      query.types ||
      query.scopeIds ||
      query.gradeIds ||
      query.subjectIds
    )
  );
  const isShown = (
    WelcomeBannerStore.state.isShown &&
    !hasSearched
  );
  WelcomeBannerStore.setState({ isShown });
});

export default WelcomeBannerStore;
