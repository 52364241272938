import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';

import Trash from '../../common/trash';
import Link from '../../common/link';
import { confirmationDialog } from '../../confirmation-dialog';
import * as announce from '../../../common/announce';
import TruncateWithTooltip from '../../common/truncate-with-tooltip';

export default createReactClass({
  displayName: 'AccountGroupsResult',

  propTypes: {
    group: PropTypes.object,
    session: PropTypes.object,
    onDelete: PropTypes.func
  },

  async openDeleteConfirmation () {
    const title = formatMessage('Delete {name}?', { name: this.props.group.name });
    const message = formatMessage('Resources will remain accessible to the original sharing user and to any other groups the resources have been shared to.');
    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Delete')
    });

    if (confirmationAnswer) {
      this.props.onDelete(this.props.group);
      announce.assertively(formatMessage('Group deleted'));
    }
  },

  render () {
    const { group, session } = this.props;
    const canDelete = session && session.isAdmin;

    return (
      <div className="AccountGroupsResult">
        <div className="AccountGroupsResult-container">
          <div className="AccountGroupsResult-name-column">
            <Link
              className="AccountGroupsResult-link"
              href={`/account/groups/${group.id}`}
            >
              <TruncateWithTooltip text={group.name} />
            </Link>
          </div>
          {canDelete && (
            <div className="AccountGroupsResult-delete-column">
              <Link
                className="AccountGroupsResult-delete-button"
                onClick={this.openDeleteConfirmation}
                ref="delete"
              >
                <span className="screenreader-only">
                  {formatMessage('Delete group {name}', {
                    name: group.name
                  })}
                </span>
                <Trash />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
});
