import React from 'react';
import InfoIconLine from '@instructure/ui-icons/lib/IconInfoLine';
import IconMoreSolid from '@instructure/ui-icons/lib/IconMoreSolid';
import { IconButton } from '@instructure/ui-buttons/lib';

import { Menu } from '@instructure/ui-menu/lib/Menu';
import { Avatar } from '@instructure/ui-avatar';
import { AppNav } from '@instructure/ui-navigation/lib/AppNav';
import formatMessage from 'format-message';
import { getState } from '../store';
import { ApplyTheme } from '@instructure/ui-themeable/lib/ApplyTheme';
import _ from 'lodash';
import { getLocalizedUrl } from '../common/localized-urls';

const VerticalSplitLine = ({ style }) => (
  <div
    style={{
      height: '24px',
      width: '1px',
      backgroundColor: '#C7CDD1',
      ...style
    }}
  />
);

const AboutItem = () => (
  <AppNav.Item
    target="_blank"
    href={getLocalizedUrl('https://community.canvaslms.com/t5/Commons/What-is-Canvas-Commons/ta-p/1788')}
    renderLabel={formatMessage('About')}
  />
);

const GuidesItem = ({ style, openGuides, help_tray }) => (
  <span style={{ ...style }}>
    <AppNav.Item
      onClick={help_tray ? openGuides : () => void 0}
      href={!help_tray && getLocalizedUrl('https://community.canvaslms.com/t5/Commons/tkb-p/commons')}
      target="_blank"
      data-automation="GuidesButton"
      renderLabel={
        <div>
          <InfoIconLine
            style={{
              marginRight: '9px',
              marginBottom: '4px'
            }}
          />
          {formatMessage('Guides')}
        </div>
      }
    />
  </span>
);

const LoginItem = () => (
  <span style={{ marginLeft: '8px', marginRight: '16px' }}>
    <AppNav.Item href="/login" renderLabel={formatMessage('Log in')} key="login" />
  </span>
);

const CommunityItem = () => (
  <AppNav.Item
    target="_blank"
    href={getLocalizedUrl('https://community.canvaslms.com/t5/Commons-Users/gh-p/commons')}
    renderLabel={formatMessage('Community')}
    key="community"
  />
);

const UserProfile = ({ userFullName, userAvatarUrl, logout }) => (
  <Menu
    placement="bottom"
    trigger={
      <Avatar
        name={userFullName}
        src={userAvatarUrl}
        size="x-small"
        showBorder="always"
        alt={formatMessage("{name}'s avatar, click to open menu", { name: userFullName })}
        margin="0 small 0 0"
        data-testid="AppHeaderAvatar"
      />
    }
  >
    <Menu.Item value="log out" onClick={logout} data-cid="userprofile-logout" data-heap-redact-text>
      {formatMessage('Log out {userFullName}', { userFullName })}
    </Menu.Item>
  </Menu>
);

const UnauthenticatedTailItems = ({ openGuides, help_tray }) => (
  <div className="App-header-tail">
    <CommunityItem />
    {!help_tray && <AboutItem />}
    <VerticalSplitLine
      style={{
        margin: '0px 8px'
      }}
    />
    <GuidesItem openGuides={openGuides} help_tray={help_tray} />
    <LoginItem />
  </div>
);

const StandaloneAuthenticatedItems = ({
  retrievalSuccessful,
  userProfileProps,
  openGuides,
  help_tray
}) => (
  <div className="App-header-tail">
    <GuidesItem help_tray={help_tray} openGuides={openGuides} />
    <VerticalSplitLine style={{ margin: '0px 16px 0px 8px' }} />
    {retrievalSuccessful && <UserProfile {...userProfileProps} />}
  </div>
);

const KebabMenuTailItems = ({ openGuides, logout, userFullName, isAuthenticated, help_tray }) => (
  <Menu
    placement="bottom"
    trigger={
      <IconButton withBackground={false} withBorder={false} margin="0 small 0 0">
        <IconMoreSolid
          data-testid="HeaderTailKebabMenu"
          size="x-small"
        />
      </IconButton>
    }
  >
    {!isAuthenticated && (
      <Menu.Item href={getLocalizedUrl('https://community.canvaslms.com/t5/Commons-Users/gh-p/commons')} target="_blank">
        {formatMessage('Community')}
      </Menu.Item>
    )}

    {!help_tray && !isAuthenticated && (
      <Menu.Item target="_blank" href={getLocalizedUrl('https://community.canvaslms.com/t5/Commons/What-is-Canvas-Commons/ta-p/1788')}>
        {formatMessage('About')}
      </Menu.Item>
    )}

    {!help_tray && !isAuthenticated && <Menu.Separator />}

    <Menu.Item
      onClick={help_tray && openGuides}
      href={!help_tray && getLocalizedUrl('https://community.canvaslms.com/t5/Commons/tkb-p/commons')}
      target="_blank"
    >
      {formatMessage('Guides')}
    </Menu.Item>

    {help_tray && isAuthenticated && <Menu.Separator />}

    {isAuthenticated ? (
      <Menu.Item value="log out" onClick={logout} data-cid="userprofile-logout" data-heap-redact-text>
        {formatMessage('Log out {userFullName}', { userFullName })}
      </Menu.Item>
    ) : (
      <Menu.Item href="/login" key="login">
        {formatMessage('Log in')}
      </Menu.Item>
    )}
  </Menu>
);

const applyHeaderCustomTheme = (Component) => (props) => (
  <ApplyTheme
    theme={{
      [AppNav.Item.theme]: {
        fontWeight: 'normal'
      }
    }}
  >
    <Component {...props} />
  </ApplyTheme>
);

const UnauthenticatedTailItemsCustomTheme = applyHeaderCustomTheme(UnauthenticatedTailItems);
const StandaloneAuthenticatedItemsCustomTheme = applyHeaderCustomTheme(
  StandaloneAuthenticatedItems
);
const GuidesItemCustomTheme = applyHeaderCustomTheme(GuidesItem);

export const HeaderTail = ({
  isAuthenticated,
  isInIframe,
  retrievalSuccessful,
  userFullName,
  userAvatarUrl,
  openGuides,
  logout,
  shouldShowBigMenu
}) => {
  const help_tray = _.get(getState(), 'featureFlags.flags.help_tray');

  const userProfileProps = {
    userFullName,
    userAvatarUrl,
    logout
  };

  if (!shouldShowBigMenu) {
    return (
      <KebabMenuTailItems
        {...userProfileProps}
        isAuthenticated={isAuthenticated}
        openGuides={openGuides}
        help_tray={help_tray}
      />
    );
  }

  if (!isAuthenticated) {
    return <UnauthenticatedTailItemsCustomTheme openGuides={openGuides} help_tray={help_tray} />;
  }

  if (!isInIframe) {
    return (
      <StandaloneAuthenticatedItemsCustomTheme
        openGuides={openGuides}
        userProfileProps={userProfileProps}
        retrievalSuccessful={retrievalSuccessful}
        help_tray={help_tray}
      />
    );
  }

  return (
    <div className="App-header-tail">
      <GuidesItemCustomTheme
        help_tray={help_tray}
        openGuides={openGuides}
        style={{ marginRight: '16px' }}
      />
    </div>
  );
};
