import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Table } from '@instructure/ui-table/lib';
import IconLink from '@instructure/ui-icons/lib/IconLinkSolid';
import { Tooltip } from '@instructure/ui-tooltip/lib';
import { IconMiniArrowUpLine } from '@instructure/ui-icons/lib';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';

import Link from '../../common/link';
import LoadMore from '../../common/load-more';
import ResourceTypeIcon from '../../icons/resource-type';
import { headers } from './const';
import { SearchActions, UsersActions } from '../../../actions/';
import RouterStore from '../../../stores/router';

const PRIVATE_RESOURCE = formatMessage(
  'This resource is private. You do not have permission to view it.'
);

export default class Results extends React.Component {
  constructor (props) {
    super(props);
    const { sortBy, sortOrder } = RouterStore.state.query;
    const header = headers.find(({ sort }) => sort === sortBy);

    this.state = {
      sortBy,
      ascending: sortOrder === 'asc',
      focusedHeaderTitle: null,
      tableCaptions: header && header.onSortedTableCaptions
    };
  }

  renderIsApproved (learningObject) {
    return learningObject.scopeIds.some(scopeId => scopeId.includes('curated-'))
      ? formatMessage('Yes')
      : '-';
  }

  renderResourceTitle (learningObject) {
    // make sure only resources that are private and do not belong to the current user
    const isPrivate =
      learningObject.isPrivate &&
      learningObject.scopeIds[0] !== this.props.session.userId;

    return isPrivate
      ? (<Tooltip
        color="inverse"
        renderTip={PRIVATE_RESOURCE}
        on={['click', 'hover', 'focus']}
        placement="end"
      >
        <span className="focusable Table--resourceLink" tabIndex={0}>
          <ResourceTypeIcon
            className="Stats--resourceIcon"
            type={learningObject.type || 'course'}
          />
          <span className="Table--truncated">{learningObject.title}</span>
        </span>
      </Tooltip>)
      : (<Link
        href={`/resources/${learningObject.id}`}
        title={learningObject.title}
      >
        <span className="Table--resourceLink">
          <ResourceTypeIcon
            className="Stats--resourceIcon"
            type={learningObject.type || 'course'}
          />
          <span className="Table--link Table--truncated">{learningObject.title}</span>
        </span>
      </Link>);
  }

  renderSourceLink (learningObject) {
    if ('sourceId' in learningObject) {
      return (
        <Link
          target="_parent"
          href={`/api/resources/${learningObject.id}/source`}
          title={learningObject.title}
        >
          <IconLink />
        </Link>
      );
    }

    return null;
  }

  handleAuthorLinkClick (author) {
    return async (event) => {
      event.stopPropagation();
      event.preventDefault();
      await UsersActions.loadUser(author.id);
    };
  }

  renderAuthorLink (learningObject) {
    const author = learningObject.authors[0];

    if (author) {
      return (
        <Link
          data-heap-redact-attributes="href"
          target="_parent"
          href={`/api/users/${author.id}/source?session-id=${this.props.session.sid}`}
          onClick={this.handleAuthorLinkClick(author)}
          title={author.name}
        >
          <span title={author.name} className="Table--truncated Table--link" data-heap-redact-text>
            {author.name}
          </span>
        </Link>
      );
    }
  }

  renderAuthorEmail (learningObject) {
    const author = learningObject.authors[0];

    if (author) {
      const mailLink = `mailto:${author.email}`;

      return (
        <Link
          data-heap-redact-attributes="href"
          href={mailLink}
          title={author.email}
        >
          <span title={author.email} className="Table--truncated Table--link" data-heap-redact-text>
            {author.email}
          </span>
        </Link>
      );
    }
  }

  handleSort = (event, { sort, onSortedTableCaptions }) => {
    const { ascending } = this.state;

    if (sort === RouterStore.state.query.sortBy) {
      const nextOrder = ascending ? 'desc' : 'asc';
      this.setState({
        ascending: !ascending
      });
      SearchActions.update({ sortOrder: nextOrder });
    } else {
      this.setState({
        sortBy: sort,
        tableCaptions: onSortedTableCaptions,
        ascending: true
      });
      SearchActions.update({ sortBy: sort, sortOrder: 'asc' });
    }
  }

  onFocus = (title) => () => {
    this.setState({
      focusedHeaderTitle: title,
    });
  }

  render () {
    const {
      isLayoutStacked,
      trailingColumnsWidthFixed,
    } = this.props;
    const isLoading = this.props.results.store.showLoading();
    const results = this.props.results.store.get();
    const fetchNext = () => this.props.fetchNext(this.props.results.nextCursor);
    const { ascending, focusedHeaderTitle, tableCaptions } = this.state;
    const direction = ascending ? 'ascending' : 'descending';
    const layout = isLayoutStacked ? 'stacked' : 'fixed';

    const getSortDirection = sort => sort === RouterStore.state.query.sortBy ? direction : 'none';

    const headerSortingLabel = focusedHeaderTitle || '';
    const tableCaption = tableCaptions ? tableCaptions[direction] : '';

    return (
      <div>
        <Table caption={tableCaption} layout={layout} data-automation="StatsTable">
          <Table.Head>
            <Table.Row>
              {headers.map(({ id, sort, title, width, textAlign, justifyRight, onClickSortingLabel, onSortedTableCaptions, automation }) =>
                sort ? (
                  <Table.ColHeader
                    key={`header-${id}`}
                    id={title}
                    aria-sort={getSortDirection(sort)}
                    data-automation={automation}
                    width={width(trailingColumnsWidthFixed)}
                    textAlign={textAlign}
                    onRequestSort={e => this.handleSort(e, { sort, onSortedTableCaptions })}
                    sortDirection={getSortDirection(sort)}
                    onFocus={this.onFocus(onClickSortingLabel)}
                  >
                    {justifyRight
                      ? (<span className="Table--ColHeader--justifyRight">{title}</span>)
                      : title}
                  </Table.ColHeader>
                ) : (
                  <Table.ColHeader
                    key={`header-${id}`}
                    id={title}
                    width={width(trailingColumnsWidthFixed)}
                    textAlign={textAlign}
                  >
                    {title}
                  </Table.ColHeader>
                )
              )}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {results.length
              ? results.map(learningObject => (
                <Table.Row
                  key={learningObject.id}
                  data-automation={learningObject.id}
                >
                  <Table.Cell data-automation="ResourceTitle">
                    {this.renderResourceTitle(learningObject)}
                  </Table.Cell>
                  <Table.Cell data-automation="Author">
                    {this.renderAuthorLink(learningObject)}
                  </Table.Cell>
                  <Table.Cell data-automation="Email">
                    {this.renderAuthorEmail(learningObject)}
                  </Table.Cell>
                  <Table.Cell data-automation="Approved">
                    {this.renderIsApproved(learningObject)}
                  </Table.Cell>
                  <Table.Cell textAlign={isLayoutStacked ? 'start' : 'end'} data-automation="Favorites">
                    {learningObject.favoritedCount}
                    <IconMiniArrowUpLine style={{ color: 'transparent' }} />
                  </Table.Cell>
                  <Table.Cell textAlign={isLayoutStacked ? 'start' : 'end'} data-automation="Downloads">
                    {learningObject.downloadCount}
                    <IconMiniArrowUpLine style={{ color: 'transparent' }} />
                  </Table.Cell>
                  <Table.Cell
                    textAlign={isLayoutStacked ? 'start' : 'center'}
                    data-automation="CanvasSource"
                  >
                    {this.renderSourceLink(learningObject)}
                  </Table.Cell>
                </Table.Row>
              ))
              : undefined}
          </Table.Body>
        </Table>
        <LoadMore
          hasMore={this.props.results.hasMore}
          isLoading={isLoading}
          loadMore={fetchNext}
        />
        <div id="headerSortingLabel" aria-live="polite" aria-atomic="true" aria-relevant="additions text">
          <ScreenReaderContent>
            {headerSortingLabel}
          </ScreenReaderContent>
        </div>
        <div id="statsTableCaption" aria-live="polite" aria-atomic="true" aria-relevant="additions text">
          <ScreenReaderContent>
            {tableCaption}
          </ScreenReaderContent>
        </div>
      </div>
    );
  }
}

Results.displayName = 'StatsResults';

Results.propTypes = {
  fetchNext: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
  isLayoutStacked: PropTypes.bool,
  trailingColumnsWidthFixed: PropTypes.bool
};

Results.defaultProps = {
  isLayoutStacked: false,
  trailingColumnsWidthFixed: false
};
