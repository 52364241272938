import React from 'react';

export default class PlaceholderResult extends React.Component {
  render () {
    return (
      <div className="lor-result-list-item">
        <div className="lor-result-placeholder">
          <div className="lor-result-placeholder-image" data-automation="ResourcePlaceholderImage">
            <div className="lor-result-placeholder-image-icon" />
          </div>
          <div className="lor-result-placeholder-wrapper">
            <div className="lor-result-placeholder-title" />

            <div className="lor-result-placeholder-line lor-result-placeholder-description-line-1" />
            <div className="lor-result-placeholder-line lor-result-placeholder-description-line-2" />
            <div className="lor-result-placeholder-line lor-result-placeholder-description-line-3" />
            <div className="lor-result-placeholder-line lor-result-placeholder-description-line-4" />

            <div className="lor-result-placeholder-line lor-result-placeholder-rating" />
            <div className="lor-result-placeholder-line lor-result-placeholder-authors" />
          </div>
        </div>
      </div>
    );
  }
}
