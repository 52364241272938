import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../components/app-handler';
import Imported from '../components/search/imported-handler';
import SearchOptions from '../components/search/search-options';
import { SearchActions, SessionActions, RouterActions } from '../actions';
import { getState, subscribe } from '../store';
import PageTitle from '../components/common/page-title';

/* eslint-disable react/prop-types */
const view = ({ q, types, scopeIds, gradeIds, subjectIds, sortBy, contentCategoryTypes }) => {
  const state = getState();
  return (
    <AppHandler
      activeHeader="imported"
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <div className="responsive-results-width">
        <Head title={formatMessage('Imported')} />
        <PageTitle title={formatMessage('Imported')} />
        <main className="Main">
          <SearchOptions
            featureFlags={state.featureFlags}
            searchPrivateObjects
            onChange={SearchActions.update}
            query={q}
            types={types ? types.split(',') : []}
            contentCategoryTypes={contentCategoryTypes ? contentCategoryTypes.split(',') : []}
            scopeIds={scopeIds ? scopeIds.split(',') : []}
            gradeIds={gradeIds ? gradeIds.split(',') : []}
            subjectIds={subjectIds}
            sortBy={sortBy}
            consortiums={state.consortiums}
            groups={state.groups.list}
            canShowApprovedContent={state.account.settings.allowApprovedContent}
            canShowPublic={
              state.account.settings.canShowPublic &&
              state.session.sid &&
              !state.session.publicOnly
            }
            queryArialLabel={formatMessage(
              'Search Commons for course resources by tag, name, institution, or outcome'
            )}
            account={state.session.account}
          />
          <Imported {...state} />
        </main>
      </div>
    </AppHandler>
  );
};

export default function ({ location, render, resolve, exiting }) {
  RouterActions.backTo(formatMessage('Imported'), window.location.href);

  render(view(location.query));
  resolve();

  const unsubscribe = subscribe(() => render(view(location.query)));
  exiting.then(unsubscribe);
}
