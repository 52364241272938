import React from 'react';
import formatMessage from 'format-message';
import TypePicker from './type-picker';

export default function ResourceTypePicker (props) {
  const types = [
    'course',
    'module',
    'assignment',
    'quiz',
    'discussion_topic',
    'page',
    'document',
    'video',
    'audio',
    'image'
  ];
  return (
    <fieldset className="ResourceTypePicker">
      <legend className="screenreader-only">
        {formatMessage('View only these types')}
      </legend>
      <div className="ResourceTypePicker-types">
        {types.map((type) => (
          <TypePicker key={type} type={type} {...props} />
        ))}
      </div>
    </fieldset>
  );
}
