import Uniflow from 'uniflow';
import RouterActions from '../actions/router';

var RouterStore = Uniflow.createStore({
  state: {
    params: {},
    query: {},
    pathname: ''
  },
  isSharePage () {
    return /^\/share($|\?|\/)/.test(this.state.path);
  },
  isSearchPage () {
    return /^\/search($|\?|\/)/.test(this.state.path);
  }
});

RouterActions.on('match', ({ location, params }) => {
  RouterStore.setState({
    ...location,
    params
  });
});

RouterActions.on('back-to-route', ({ name, uri }) => {
  RouterStore.setState({
    backTo: { name, uri }
  });
});

export default RouterStore;
