import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import classNames from 'classnames';
import { Spinner } from '@instructure/ui-spinner/lib';

export default function LoadingSpinner ({ size = 'medium', message, className, ...rest }) {
  message = message || formatMessage('Loading...');

  className = classNames('loading-spinner', className);

  return (
    <div className={className} {...rest}>
      <Spinner renderTitle={() => message} size={size} />
      <span className="screenreader-only">{message}</span>
    </div>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  message: PropTypes.node,
  className: PropTypes.string
};
