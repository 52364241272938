import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import { ConsortiumsActions, SessionActions } from '../../actions';
import AppHandler from '../../components/app-handler';
import Consortiums from '../../components/account/consortiums';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import AccountHeader from './account-header';

const view = () => {
  const state = getState();
  const isAllowed = state.session.isAdmin;
  if (!isAllowed) {
    return <NotAuthenticated />;
  }

  return (
    <AppHandler
      activeHeader="account"
      session={state.session}
      updates={state.updates}
      logout={SessionActions.logout}
    >
      <Head title={formatMessage('Consortiums')} />
      <div className="AccountSettings App-main-inner-main-content-width">
        <AccountHeader />
        <AdminTabs
          activeTab="consortiums"
          session={state.session}
          isManager={!!state.groups.isManager}
        />
        <h2 className="Consortiums-title">{formatMessage('Consortiums')}</h2>
        <Consortiums
          {...state.consortiums}
          session={state.session}
          {...ConsortiumsActions}
        />
      </div>
    </AppHandler>
  );
};

export default function ({ resolve, render, exiting }) {
  ConsortiumsActions.reset();
  ConsortiumsActions.loadList();
  render(view());
  resolve();
  const unsubscribe = subscribe(() => render(view()));
  exiting.then(unsubscribe);
}
