import React from 'react';
import PropTypes from 'prop-types';
import * as LearningObject from '../../../models/resource';
import formatMessage from 'format-message';

export default class LearningObjectImagePreview extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleError = this.handleError.bind(this);

    this.state = {
      hasError: false
    };
  }

  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  UNSAFE_componentWillReceiveProps () {
    this.setState({ hasError: false });
  }

  render () {
    var alt = this.props.learningObject.title;
    var src = LearningObject.getImagePreviewUrl(this.props.learningObject.id);
    var style = {};
    if (this.state.hasError) {
      style.display = 'none';
    }

    return (
      <section>
        <h2 className="screenreader-only">{formatMessage('Image Preview')}</h2>
        <img
          className="lor-image-preview"
          style={style}
          alt={alt}
          src={src}
          onError={this.handleError}
        />
      </section>
    );
  }

  handleError () {
    this.setState({ hasError: true });
  }
}
