import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

// Components
import SearchObjects from '../search/search-objects';
import NoResult from '../common/no-result';
import Loading from '../common/loading';

// Actions
import SearchActions from '../../actions/search';

export default class AccountSharedObjects extends React.Component {
  static displayName = 'AccountSharedObjects'

  static propTypes = {
    session: PropTypes.object.isRequired,
    groups: PropTypes.object
  }

  render () {
    const { groups, session, results } = this.props;
    const { isAdmin, account, publicOnly, isAccountCurator = false } = session;

    if (groups.listIsLoading) {
      return (
        <div className="AppHandler--loading">
          <Loading />
        </div>
      );
    }

    const baseParams = {};

    if (isAdmin || isAccountCurator) {
      baseParams.accountId = account.id;
      baseParams.showPublic = true;
    }

    const canShowPublic = (isAdmin || isAccountCurator) && !publicOnly;
    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Resources To Manage');

    const fetchManagedResults = (params, cursor) => SearchActions.fetchResultsWithPath(params, cursor, '/resources/managed');
    fetchManagedResults.constructor.prototype.partial = (params) => {
      const partialApplied = (cursor) => () => fetchManagedResults(params, cursor);
      partialApplied.constructor.prototype.partial = (cursor) => () => fetchManagedResults(params, cursor);
      return partialApplied;
    };

    return (
      <SearchObjects
        baseParams={baseParams}
        fetchNext={fetchManagedResults}
        showDeleteOptions
        searchPrivateObjects={false}
        emptyMessage={
          <NoResult
            title={noResultsMessage}
            className="AccountResources-no-result"
          />
        }
        canShowPublic={canShowPublic}
        {...this.props}
      />
    );
  }
}
