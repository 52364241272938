import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Flex } from '@instructure/ui-flex/lib';
import { Tabs } from '@instructure/ui-tabs/lib';
import { Grid } from '@instructure/ui-grid/lib';
import { View } from '@instructure/ui-layout';
import IconCalendarDay from '@instructure/ui-icons/lib/IconCalendarDayLine';
import IconStarLight from '@instructure/ui-icons/lib/IconStarLightLine';
import IconCollectionSave from '@instructure/ui-icons/lib/IconCollectionSaveLine';
import { AccessibleContent } from '@instructure/ui-a11y/lib';

import HandlesErrors from '../common/handles-errors';
import * as announce from '../../common/announce';
import MessageBanner from './message-banner';
import ComplexMessage from '../common/complex-message';
import BackButton from './back-button';
import LearningObjectToolbar from './toolbar';
import Header from './header';
import Info from './info';
import RedSorry from '../errors/layouts/red-sorry';
import Reviews from './reviews/reviews';
import Link from '../common/link';
import Loading from '../common/loading';
import NotifyMessage from '../common/notify-message';
import { shortenLargeNumber } from '../../common/util/string';
import License from './license';
import SummaryTab from './tabs/summary';
import VersionNotes from './tabs/version-notes';
import ResourcePreviewer from './preview/resource';
import RouterStore from '../../stores/router';
import UpdatesStore from '../../stores/updates';
import RouterActions from '../../actions/router';
import LearningObjectActions from '../../actions/resource';
import UpdatesActions from '../../actions/updates';

export default createReactClass({
  displayName: 'LearningObjectIndex',

  mixins: [HandlesErrors],

  propTypes: {
    session: PropTypes.object.isRequired,
    courses: PropTypes.object,
    groups: PropTypes.object,
    learningObjectId: PropTypes.string,
    resource: PropTypes.object.isRequired,
    resultPath: PropTypes.object,
    tab: PropTypes.string,
    updates: PropTypes.object.isRequired,
    reviews: PropTypes.array
  },

  getDefaultProps () {
    return {
      tab: 'summary'
    };
  },

  getInitialState () {
    return {
      importRetry: null,
      selectedTabIndex: 0
    };
  },

  componentDidMount () {
    this.handleSessionStoreChange(this.props);
  },

  handleLearningObjectStoreChange (props) {
    const notFound = 'Not Found';
    const findErrors = props.resource.findErrors;

    if (findErrors && findErrors.message === notFound) {
      RouterActions.transitionTo('/errors/404');
    }

    if (findErrors) {
      const msg = (
        <div>
          <ComplexMessage>
            {formatMessage(
              `{error_message}
                You can go back to search results by {clicking_search}
                or try reloading the page by {clicking_reload}.`,
              {
                error_message: ComplexMessage.placeholder('strong'),
                clicking_search: ComplexMessage.placeholder('search'),
                clicking_reload: ComplexMessage.placeholder('reload')
              }
            )}
            <strong key="strong">
              {formatMessage('The requested learning resource failed to load.')}
            </strong>
            <Link key="search" href="/search" query={RouterStore.state.query}>
              {formatMessage('clicking here')}
            </Link>
            <Link
              key="reload"
              onClick={LearningObjectActions.find.partial(
                props.learningObjectId
              )}
            >
              {formatMessage('clicking here')}
            </Link>
          </ComplexMessage>
        </div>
      );
      return this.replaceErrors({ param: 'find-object', msg: msg });
    }

    const importResults = props.resource.importResults;
    const failures = importResults.filter(result => result.status === 'failed');

    if (failures.length !== 0) {
      const failedCourse = failures[0];
      const learningObjectId = props.learningObjectId;
      const newState = {
        importRetry: LearningObjectActions.importIntoCourse.bind(
          null,
          {
            learningObjectId,
            courseId: failedCourse.id,
            courseName: failedCourse.name
          }
        )
      };
      if (failedCourse.code === 401) {
        newState.message = {
          type: 'error',
          message: formatMessage(
            "You don't have permission to import into that course. Check with your Canvas administrator if you need access."
          )
        };
      }
      return this.setState(newState);
    }
  },

  genReviewsEditingUser () {
    if (this.props.session) {
      return {
        id: this.props.session.userId,
        fullName: this.props.session.userFullName,
        avatarUrl: this.props.session.userAvatarUrl
      };
    }
  },

  handleSessionStoreChange (props) {
    if (
      props.session.retrievalSuccessful &&
      !UpdatesStore.getUpdateForLearningObject(props.learningObjectId) &&
      !props.updates.getUpdatesPending
    ) {
      UpdatesActions.getUpdateForLearningObject(
        props.session.user.id,
        props.learningObjectId
      );
    }
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.handleLearningObjectStoreChange(nextProps);
    this.handleSessionStoreChange(nextProps);
  },

  handleImportRetry (error) {
    error.preventDefault();
    this.setState({
      importRetry: null,
      message: null
    });
    if (typeof this.state.importRetry === 'function') {
      this.state.importRetry();
    }
  },

  componentWillUnmount () {
    LearningObjectActions.clear();
    announce.reset();
  },

  render () {
    if (this.hasErrorsWithId('find-object')) {
      return <RedSorry>{this.renderErrorsWithId('find-object')}</RedSorry>;
    }

    if (
      this.props.resource.findPending ||
      !this.props.resource.findSuccessful
    ) {
      return (
        <div className="lor-course-layout">
          <Grid colSpacing="large" rowSpacing="small">
            <Grid.Row>
              <Grid.Col />
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <article className="lor-course-article lor-course-article--loading">
                  <Loading />
                </article>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </div>
      );
    }

    const query = RouterStore.state.query || {};

    const learningObject = this.props.resource.learningObject;
    const version = (learningObject.versions || [])[0] || {};

    const update = UpdatesStore.getUpdateForLearningObject(
      this.props.learningObjectId
    );
    const courses = this.props.courses;
    const groups = this.props.groups;

    const hasMultipleVersions = !(
      learningObject.versions && learningObject.versions.length > 1
    );

    const isPrivate =
      learningObject.scopeIds.length === 1 &&
      learningObject.scopeIds[0] === learningObject.createUserId;

    // isCreator OR isAdmin AND the account id of the learning object matches the account
    // id of the session AND it is not a private learning object
    // OR isManager of the group the resource is shared to
    const {
      isAdmin = false,
      isAccountCurator = false,
      account
    } = this.props.session;

    const session = this.props.session;

    const reviews = this.props.reviews;
    const canSeeToolbar =
      learningObject.isCreator ||
      (isAdmin && learningObject.account.id === account.id && !isPrivate) ||
      (isAccountCurator && learningObject.account.id === account.id) ||
      learningObject.scopes.some(learningObjectScope => {
        return groups.list.find(
          group =>
            'group-' + group.id === learningObjectScope.id && group.isManager
        );
      });

    const tabPanels = [
      {
        id: 'preview',
        title: formatMessage('Preview'),
        disabled: false,
        content: (
          <ResourcePreviewer resource={learningObject} session={session} />
        )
      },
      {
        id: 'details',
        title: formatMessage('Details'),
        disabled: false,
        content: (
          <div>
            <SummaryTab
              resource={learningObject}
              session={session}
              reviews={reviews}
              showPreview={false}
            />
            <hr aria-hidden="true" />
            <Reviews
              ref="reviews"
              editingUser={this.genReviewsEditingUser()}
              learningObject={learningObject}
              reviews={reviews}
              isAuthenticated={!!session.sid}
            />
          </div>
        )
      },
      {
        id: 'versions',
        title: formatMessage('Version notes'),
        disabled: hasMultipleVersions,
        content: (
          <VersionNotes
            resource={learningObject}
            session={session}
            reviews={reviews}
            accountSettings={this.props.account.settings}
          />
        )
      }
    ];

    return (
      <div className="lor-course-layout">
        <Flex direction="column">
          {
            canSeeToolbar && (
              <Flex.Item overflowY="visible" shouldGrow shouldShrink>
                <div className="lor-course-admin-bar">
                  <LearningObjectToolbar
                    query={query}
                    learningObjectId={learningObject.id}
                    learningObjectTitle={learningObject.title}
                  />
                </div>
              </Flex.Item>
            )
          }
          <Flex.Item overflowY="hidden" margin="medium 0">
            <div className="lor-learning-object-nav">
              <BackButton
                backTo={this.props.router ? this.props.router.backTo : {}}
              />
              <Flex margin="0 small 0 0" wrap="wrap">
                <Flex.Item>
                  <div className="lor-learning-object-nav-text-with-icon">
                    <IconStarLight />
                    <div className="lor-instui-cursor-default lor-instui-simple-button lor-favorites-count">
                      {formatMessage('{count} Favorites', {
                        count: shortenLargeNumber(
                          learningObject.favoritedCount,
                          1
                        )
                      })}
                    </div>
                  </div>
                </Flex.Item>
                <Flex.Item>
                  <div className="lor-learning-object-nav-text-with-icon">
                    <IconCollectionSave />
                    <div className="lor-instui-cursor-default lor-instui-simple-button lor-downloads-count">
                      {formatMessage('{count} Downloads', {
                        count: shortenLargeNumber(
                          learningObject.downloadCount,
                          1
                        )
                      })}
                    </div>
                  </div>
                </Flex.Item>
                {version.createDate && (
                  <Flex.Item>
                    <div className="lor-learning-object-nav-text-with-icon">
                      <View margin="0 x-small 0 0">
                        <IconCalendarDay />
                      </View>
                      <AccessibleContent
                        title={formatMessage('Last update')}
                        className="lor-instui-cursor-default lor-instui-simple-button"
                        role="presentation"
                        alt={formatMessage('Last updated on { datetime, date, short }', {
                          datetime: new Date(version.createDate)
                        })}
                      >
                        {formatMessage('{ datetime, date, short }', {
                          datetime: new Date(version.createDate)
                        })}
                      </AccessibleContent>
                    </div>
                  </Flex.Item>
                )}
                {Array.isArray(learningObject.licenseIds) &&
                    learningObject.licenseIds[0] && (
                  <Flex.Item>
                    <div className="lor-learning-object-license">
                      {
                        <License
                          licenseId={learningObject.licenseIds[0]}
                          licenseSpecs={learningObject.licenseSpecs}
                        />
                      }
                    </div>
                  </Flex.Item>
                )}
              </Flex>
            </div>
          </Flex.Item>
          <Flex.Item>
            <MessageBanner
              learningObject={{ ...learningObject }}
              isRecentlyCreated={query.created === 'true'}
              isRecentlyImported={
                query.importing === 'true' || !!this.state.importRetry
              }
              handleImportRetry={
                this.state.importRetry && this.handleImportRetry
              }
              message={this.state.message}
            />
          </Flex.Item>
          <Flex.Item overflowX="hidden">
            <div className="lor-learning-object-content">
              <div className="lor-course-info-with-update App-main-inner-main-content-width">
                {update && (
                  <NotifyMessage title={formatMessage('Update')} kind="alert">
                    <div className="LearningObjectDetail-update">
                      <span className="LearningObjectDetail-updateText">
                        {formatMessage(
                          'Update your courses with the latest version.'
                        )}
                      </span>
                      <Link
                        isWithinText
                        className="LearningObjectDetail-updateButton"
                        href="/updates"
                      >
                        {formatMessage('Show Courses')}
                      </Link>
                    </div>
                  </NotifyMessage>
                )}
                <article className="lor-course-article">
                  <Header learningObject={learningObject} />
                  {<Tabs
                    onRequestTabChange={(_, { index }) => this.setState({ selectedTabIndex: index })}
                  >
                    {tabPanels.map((tab, index) => (
                      <Tabs.Panel
                        key={tab.id}
                        title={tab.title}
                        disabled={tab.disabled}
                        selected={index === this.state.selectedTabIndex}
                      >
                        {tab.content}
                      </Tabs.Panel>
                    ))}
                  </Tabs>}
                </article>
              </div>
              <Info
                learningObject={learningObject}
                isAuthenticated={session.isAuthenticated}
                limited={session.limited}
                accountSettings={this.props.account.settings}
                courses={courses}
                session={session}
                favorites={this.props.favorites}
                onUpdate={() => {
                  // child components aren't able to
                  // trigger view updates for this component
                  // forcing a setState (dummy info) here to trigger it
                  this.setState({ updated: true });
                }}
              />
            </div>
          </Flex.Item>
        </Flex>
      </div>
    );
  }
});
