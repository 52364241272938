import React from 'react';
import classnames from 'classnames';
import formatMessage from 'format-message';
import View from '@instructure/ui-layout/lib/View';
import { Text } from '@instructure/ui-text/lib';
import IconWarningLine from '@instructure/ui-icons/lib/IconWarningLine';
import IconCompleteLine from '@instructure/ui-icons/lib/IconCompleteLine';

import CroppedImg from '../common/cropped-img';
import LoadingFavoritesTray from '../common/loading-favorites-tray';
import ResourceTypeIcon from '../icons/resource-type';
import { resourceTypes } from '../../common/app-constants';
import TruncateWithTooltip from '../common/truncate-with-tooltip';
import Button from '../common/button';

const CardView = props => (
  <View
    as="div"
    borderRadius="medium"
    borderWidth="small"
    overflowX="auto"
    overflowY="auto"
    {...props}
  />
);

const FavoriteResourceCard = React.forwardRef((props, ref) => {
  const {
    returnUrl,
    resource,
    handleClick,
    handleDismiss,
    body,
    error,
    isImporting,
    showSuccess
  } = props;
  let croppedImage = null;
  const hasThumbnail = resource && resource.thumbnail && resource.thumbnail.url;
  if (hasThumbnail) {
    croppedImage = (
      <CroppedImg
        noBorderRadius
        className="FavoriteResourceCard-image-favoritesTray"
        src={resource.thumbnail.url}
        alt={resource.thumbnail.description}
      />
    );
  }
  const type = resource.type;
  const buttonClassNames = classnames(
    'FavoriteResourceSmall-button-favoritesTray',
    {
      'FavoriteResourceSmall-button-error': error
    }
  );
  return (
    <li
      className="FavoritesResourceCard"
      data-testid={`favorite-resource-card-${resource.id}`}
    >
      <form
        action={returnUrl}
        id={`passback-${resource.id}`}
        method="post"
        name="favoritePassback"
      >
        <Button
          className={buttonClassNames}
          onClick={handleClick}
          ref={ref}
          display={Button.display.block}
          textAlign={Button.textAlign.start}
        >
          <CardView className="FavoriteResourceSmall-root">
            <input
              name="lti_message_type"
              type="hidden"
              value="ContentItemSelection"
            />
            <input type="hidden" name="lti_version" value="LTI-1p0" />
            <input
              name="content_items"
              type="hidden"
              value={JSON.stringify(body)}
            />
            <div className="FavoriteResourceSmall-favoritesTray">
              <View
                as="div"
                className="FavoriteResourceSmall-image-favoritesTray"
              >
                {croppedImage}
              </View>
              <View as="div" height="70px" width="100%">
                <div className="FavoriteResourceSmall-type">
                  <div>
                    <ResourceTypeIcon
                      iconSize="x-small"
                      aria-label={resourceTypes[type].singularName}
                      className="SearchResultThumbnail-icon FavoriteResourceCard-icon"
                      type={type}
                      colored
                      ghost
                      raw
                    />
                  </div>
                  <Text
                    size="small"
                    color="primary"
                    transform="uppercase"
                    letterSpacing="expanded"
                  >
                    {resourceTypes[type].singularName}
                  </Text>
                </div>
                <View
                  width="100%"
                  as="div"
                  textAlign="start"
                  padding="none small none small"
                >
                  <TruncateWithTooltip text={resource.title} />
                </View>
              </View>
            </div>
          </CardView>
        </Button>
      </form>
      {error && (
        <CardView
          className="FavoriteResourceCard--overlay-favoritesTray FavoriteResourceCard--error-favoritesTray"
          data-testid="favorite-resource-card-error"
        >
          <div>
            <IconWarningLine color="error" size="small" />
          </div>
          <div className="FavoriteResourceCard-error-text">
            {formatMessage('An error occured while importing the resource')}
          </div>
          <div className="FavoriteResourceCard-error-button-group">
            <div className="FavoriteResourceCard-error-button">
              <Button
                color={Button.color.primary}
                size={Button.size.small}
                display={Button.display.block}
                textAlign={Button.textAlign.start}
                onClick={handleClick}
              >
                {formatMessage('Retry')}
              </Button>
            </div>
            <div className="FavoriteResourceCard-error-button">
              <Button size={Button.size.small} fluidWidth onClick={handleDismiss}>
                {formatMessage('Dismiss')}
              </Button>
            </div>
          </div>
        </CardView>
      )}
      {isImporting && (
        <CardView
          className="FavoriteResourceCard--overlay-favoritesTray FavoriteResourceCard--loading-favoritesTray"
          data-testid="favorite-resource-card-import-progress"
        >
          <LoadingFavoritesTray />
        </CardView>
      )}
      {showSuccess && (
        <CardView
          className="FavoriteResourceCard--overlay-favoritesTray FavoriteResourceCard--loading-favoritesTray"
          data-testid="favorite-resource-card-import-success"
        >
          <IconCompleteLine size="medium" color="success" />
        </CardView>
      )}
    </li>
  );
});
export default FavoriteResourceCard;
