let scheduledReset = setTimeout(function () {}, 0);

/** @type {(text: string) => void} */
export function politely (text) {
  clearTimeout(scheduledReset);
  var id = 'screenreader-announcements-polite';
  var element = document.getElementById(id);
  element.textContent = text;
}

/** @type {(text: string) => void} */
export function assertively (text) {
  clearTimeout(scheduledReset);
  var id = 'screenreader-announcements-assertive';
  var element = document.getElementById(id);
  element.textContent = text;
}

/** @type {() => void} */
export function reset () {
  assertively('');
  politely('');
}

const FIVE_SECONDS = 5000;
export function delayedReset () {
  scheduledReset = setTimeout(reset, FIVE_SECONDS);
}
