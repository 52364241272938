import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../components/app-handler';
import Updates from '../components/updates';
import { UpdatesActions, SessionActions, RouterActions, NotificationCenterActions } from '../actions';
import { getState, subscribe } from '../store';
import PageTitle from '../components/common/page-title';

const view = () => {
  const state = getState();
  return (
    <AppHandler
      activeHeader="updates"
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <Head title={formatMessage('Updates')} />
      <PageTitle title={formatMessage('Updates')} />
      <Updates
        session={state.session}
        updates={state.updates}
      />
    </AppHandler>
  );
};

export default function ({ params, render, exiting, resolve }) {
  RouterActions.backTo(formatMessage('Updates'), window.location.href);

  const userId = getState().session.userId;
  UpdatesActions.refresh();
  UpdatesActions.getUpdates(userId);
  render(view());

  const unsubscribe = subscribe(() => render(view()));
  exiting.then(() => {
    NotificationCenterActions.closeAllNotificationsOfType('update');
    unsubscribe();
  });

  resolve();
}
