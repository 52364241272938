import Uniflow from 'uniflow';
import NotificationCenterActions from '../actions/notification-center';

const NotificationCenterStore = Uniflow.createStore({
  state: {
    currentNotifications: []
  },
  addNotification (notification) {
    var currentNotifications = this.state.currentNotifications.slice();

    if (this.state.currentNotifications.map((m) => m.id).indexOf(notification.id) === -1) {
      currentNotifications.push(notification);
    }

    this.setState({
      currentNotifications
    });
  },

  closeNotification (id) {
    const index = this.state.currentNotifications.map((m) => m.id).indexOf(id.toString());

    if (index !== -1) {
      var currentNotifications = this.state.currentNotifications.slice();
      currentNotifications.splice(index, 1);
      window.localStorage.lastUpdateId = id;
      this.setState({
        currentNotifications
      });
    }
  },

  closeNotificationType (type) {
    this.setState({
      currentNotifications:
        this.state.currentNotifications.filter((notification) => notification.type !== type)
    });
  }
});

NotificationCenterActions.on('show-notification', NotificationCenterStore.addNotification);
NotificationCenterActions.on('close-notification', NotificationCenterStore.closeNotification);
NotificationCenterActions.on('close-notification-type', NotificationCenterStore.closeNotificationType);

export default NotificationCenterStore;
