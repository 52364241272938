import React, { useEffect, useState } from 'react';
import axios from 'axios';
import env from '../../../config/env';

const getProgressForResourceId = async (resourceId) => {
  const request = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'x-session-id': env.SESSION_ID
    }
  });
  const response = await request.get(`/api/common-cartridges/${resourceId}/progress`);
  return response.data;
};

const retryFailedJobForResource = async (resourceId) => {
  const request = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'x-session-id': env.SESSION_ID
    }
  });
  const response = await request.post(`/api/common-cartridges/${resourceId}/retry`);
  return response.data;
};

const setIntervalFireImmediately = (fn, interval) => {
  fn();
  return setInterval(fn, interval);
};

const ResourceProgressPoller = ({ resource, children }) => {
  const [progress, setProgress] = useState(null);
  const [inProgress, setInProgress] = useState(true);

  let interval = null;
  useEffect(() => {
    const checker = async () => {
      interval = setIntervalFireImmediately(async () => {
        const progress = await getProgressForResourceId(resource.id);
        setProgress(progress);
      }, 7000);
    };
    checker();
    return () => interval && clearInterval(interval);
  }, [resource.id]);

  const phase = progress && progress.phase;
  const state = progress && progress.state;
  const onFinish = () => {
    setInProgress(false);
    clearInterval(interval);
  };

  useEffect(() => {
    if (phase === 'common_cartridge_manager_processing' && state === 'not_found') {
      setTimeout(() => {
        if (inProgress) {
          onFinish();
        }
      }, 7000);
    }
  }, [phase, state]);

  const retryFailedJob = () => {
    setInProgress(true);
    retryFailedJobForResource(resource.id);
  };
  return children(inProgress, progress, onFinish, retryFailedJob);
};

const ResourceProgressStateHandler = ({ resource, children, isNewShareWorkflow }) => {
  if (!isNewShareWorkflow) {
    return children(false);
  }

  const { exportStatus } = resource;
  if (exportStatus !== 'incomplete') {
    return children(false);
  }

  return (
    <ResourceProgressPoller resource={resource}>
      {(inProgress, progress, onFinish, retryFailedJob) => children(inProgress, progress, onFinish, retryFailedJob)}
    </ResourceProgressPoller>
  );
};

export default ResourceProgressStateHandler;
