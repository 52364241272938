import tracker from '../common/analytics/analytics';
import session from './session';
import resource from './resource';
import RouterStore from '../stores/router';
import Uniflow from 'uniflow';

const REPORTED_KEY = 'GA-ReportedOnCanvasLaunch';

const AnalyticsActions = Uniflow.createActions({
  trackLaunchType ({ canvasCourseId, sid, contextType }) {
    if (window.sessionStorage.getItem(REPORTED_KEY) !== sid) {
      // search page
      if (RouterStore.isSearchPage()) {
        if (canvasCourseId === '$Canvas.course.id') {
          tracker.logAction('Import_LOR_Object', 'Click', 'Launch_LOR', '1');
        } else {
          tracker.logAction('Import_LOR_Object', 'Click', 'Import_LOR_Object', '1', {
            nonInteraction: 1
          });
        }
      }
      // share page
      if (canvasCourseId !== '$Canvas.course.id' && RouterStore.isSharePage()) {
        if (contextType === 'course') {
          tracker.logAction('Export_LOR_Object', 'Click', 'Export_LOR_Course_Start', '1');
        } else {
          tracker.logAction('Export_LOR_Object', 'Click', 'Export_LOR_Object_Start', '1');
        }
      }
      window.sessionStorage.setItem(REPORTED_KEY, sid);
    }
  },

  handleSessionLoad (session) {
    this.setUserDimension(session);
    this.trackLaunchType(session);
  },

  newSearchedWord (query) {
    tracker.logAction('Search', 'Click', query);
  },

  selectedCourseToImport () {
    tracker.logAction('Import_LOR_Object', 'Click', 'Import_LOR_Object_CourseSelect', '1', {
      nonInteraction: 1
    });
  },

  trackSuccessfulSingleImport  () {
    tracker.logAction(
      'Import_LOR_Object',
      'View',
      'Import_LOR_Success',
      1,
      { nonInteraction: 1 }
    );
  },

  logResultSelection () {
    tracker.logAction('Import_LOR_Object', 'Click', 'Select_LOR_Object', '1');
  },

  viewedSearchResults (numResults) {
    const value = numResults ? '1' : '0';
    tracker.logAction('Import_LOR_Object', 'View', 'View_LOR_Search_Results', value);
  },

  viewedAccountStatsResults (numResults) {
    const value = numResults ? '1' : '0';
    tracker.logAction('Import_LOR_Object', 'View', 'View_LOR_Account_Stats_Results', value);
  },

  setUserDimension ({ canvasApiDomain }) {
    tracker.setUserDimension(canvasApiDomain);
  },

  tagAdded (text) {
    tracker.logAction('Tag', 'Add', text, {
      nonInteraction: 1
    });
  },

  tagRemoved (text) {
    tracker.logAction('Tag', 'Remove', text || '', {
      nonInteraction: 1
    });
  },

  timeToImport () {
    const timeToImport = Date.now() - performance.timing.navigationStart;
    tracker.logTime('Share', 'successfullyImported', timeToImport);
  },

  endSharingTimeRecording (isUpdate) {
    const timeToShare = Date.now() - performance.timing.navigationStart;
    if (isUpdate) {
      tracker.logTime(
        'Share',
        'successfullyUpdatedShared',
        timeToShare);
    } else {
      tracker.logTime(
        'Share',
        'successfullySharedNew',
        timeToShare);
    }
  },
  completeExport () {
    tracker.logAction('Export_LOR_Object', 'Click', 'Export_LOR_Object_Complete', '1');
  },
  handleShareActions (isUpdate) {
    if (isUpdate) {
      resource.once('learning-object-update-new-version-success', () => {
        this.endSharingTimeRecording(isUpdate);
        this.completeExport();
      });
    } else {
      resource.once('learning-object-create-success', () => {
        this.endSharingTimeRecording(isUpdate);
        this.completeExport();
      });
    }
  }
});

session.once('session-retrieval-success', AnalyticsActions.handleSessionLoad);
resource.on('learning-object-import-result', AnalyticsActions.trackSuccessfulSingleImport);

export default AnalyticsActions;
