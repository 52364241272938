import React from 'react';
import PropTypes from 'prop-types';
import Select from './select';
import usStates from '../../constants/united-states-states';

export default class OutcomeAuthoritySelect extends React.Component {
  static displayName = 'OutcomeAuthoritySelect'

  static propTypes = {
    noneLabel: PropTypes.string
  }

  render () {
    const noneOption = [{ id: 'NONE', name: this.props.noneLabel || '' }];
    const statesWithNoneValue = noneOption.concat(usStates);
    const { noneLabel, ...props } = this.props;

    return (
      <Select {...props}>
        {statesWithNoneValue.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>
    );
  }
}
