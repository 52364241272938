import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import env from '../../../config/env';

export default class ResourcePreviewDocument extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  render () {
    const { learningObject } = this.props;
    const { canvadocsDocumentId } = (learningObject.versions || [])[0] || {};
    const { documentPreview } = (learningObject.meta || {}).links || {};
    if (!canvadocsDocumentId || !documentPreview) return <div />;

    return (
      <section>
        <h2 className="screenreader-only">
          {formatMessage('Document Preview')}
        </h2>
        <iframe
          className="lor-document-preview"
          src={`${documentPreview}?session-id=${env.SESSION_ID}`}
          allowFullScreen
        />
      </section>
    );
  }
}
