import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import usStates from '../constants/united-states-states';

export const RESET = 'outcomes.reset';
export const SEARCH_STANDARDS_START = 'outcomes.search-standards-start';
export const SEARCH_STANDARDS_CANCEL = 'outcomes.search-standards-cancel';
export const SEARCH_STANDARDS_SUCCESS = 'outcomes.search-standards-success';
export const SEARCH_STANDARDS_FAILURE = 'outcomes.search-standards-failure';
export const SET_SHOW_FEDERAL_STANDARDS = 'outcomes.set-show-federal-standards';
export const SET_STATE_AUTHORITY = 'outcomes.set-state-authority';
export const SET_SHOW_STATE_STANDARDS = 'outcomes.set-show-state-standards';

export const creators = {
  reset: () => ({ type: RESET }),
  searchStandardsStart: (query) => ({ type: SEARCH_STANDARDS_START, query }),
  searchStandardsCancel: () => ({ type: SEARCH_STANDARDS_CANCEL }),
  searchStandardsSuccess: (result) => ({ type: SEARCH_STANDARDS_SUCCESS, result }),
  searchStandardsFailure: (error) => ({ type: SEARCH_STANDARDS_FAILURE, error }),
  setShowFederalStandards: (value) => ({ type: SET_SHOW_FEDERAL_STANDARDS, value }),
  setStateAuthority: (value) => ({ type: SET_STATE_AUTHORITY, value }),
  setShowStateStandards: (value) => ({ type: SET_SHOW_STATE_STANDARDS, value })
};

let searchRequest;

export default function createConsortiumActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async search (query, usState, federal) {
      if (searchRequest && searchRequest.abort) {
        searchRequest.abort();
        searchRequest = null;
      }

      if (!query || (!usState && !federal)) {
        actions.searchStandardsCancel();
        return;
      }

      actions.searchStandardsStart(query);
      const qs = { query };
      if (federal && usState === 'NONE') {
        // everything, no param necessary
      } else {
        let authority = [];
        if (usState === 'NONE') {
          authority = usStates.map(({ id }) => id).filter((id) => id !== 'NONE');
        } else if (usState) {
          authority.push(usState);
        }
        if (federal) {
          authority.push('CC');
        }
        qs.authority = authority.join(',');
      }
      searchRequest = apiRequest({
        path: '/outcomes/query',
        query: qs
      });

      try {
        const response = await searchRequest.promise();
        actions.searchStandardsSuccess(response.body);
      } catch (err) {
        actions.searchStandardsFailure(err);
      } finally {
        searchRequest = null;
      }
    }
  };
}
