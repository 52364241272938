import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import Link from '../../common/link';

export default class ReviewsSummary extends React.Component {
  static propTypes = {
    onClickLink: PropTypes.func,
    showLink: PropTypes.bool,
    showTitle: PropTypes.bool,
    learningObject: PropTypes.object,
    isAuthenticated: PropTypes.bool
  }

  static defaultProps = {
    showLink: false,
    showTitle: false,
    learningObject: {}
  }

  renderLink () {
    if (this.props.isAuthenticated && this.props.showLink) {
      return (
        <Link
          ref="link"
          className="lor-reviews-summary-link"
          onClick={this.props.onClickLink}
        >
          {formatMessage('Leave a Review')}
        </Link>
      );
    }
  }

  renderTitle () {
    if (this.props.showTitle) {
      return (
        <h2 ref="title" className="lor-reviews-summary-title">
          {formatMessage('Reviews')}
        </h2>
      );
    }
  }

  render () {
    return (
      <div className="lor-reviews-summary">
        {this.renderTitle()}
        {this.renderLink()}
      </div>
    );
  }
}
