import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

// Components
import SearchObjects from '../search/search-objects';
import NoResult from '../common/no-result';

// Actions
import SearchActions from '../../actions/search';

// Stores
import ResultsStore from '../../stores/results';

export default class SharedHandler extends React.Component {
  static displayName = 'SharedHandler'
  static isResultView = true

  static propTypes = {
    session: PropTypes.object
  }

  render () {
    const { session, results } = this.props;
    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Shared Resources');

    return (
      <SearchObjects
        baseParams={{ authorIds: [session.userId] }}
        fetchNext={SearchActions.fetchResults}
        queryArialLabel={formatMessage(
          'Search My Shared Resources for course resources by keyword, author, institution, or title'
        )}
        isLoading={ResultsStore.showLoading()}
        showDeleteOptions
        searchPrivateObjects
        emptyMessage={<NoResult title={noResultsMessage} />}
        canShowPublic={!session.publicOnly}
        {...this.props}
      />
    );
  }
}
