import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import IconDownload from '@instructure/ui-icons/lib/IconDownloadLine';

import alertDialog from '../alert-dialog';
import * as LearningObject from '../../models/resource';
import LearningObjectStore from '../../stores/resource';
import Link from '../common/link';
import Button from '../common/button';

const toDataURL = async (url) => URL.createObjectURL(await (await fetch(url)).blob());

export default class ResourceDownloadLink extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired,
    limited: PropTypes.bool
  }

  render () {
    const { learningObject } = this.props;
    const url = LearningObject.getDownloadUrl(learningObject.id);

    return (
      <div className="lor-course-download">
        <Link
          tabIndex="-1"
          href={url}
          target="_blank"
          onClick={() => {
            if (!this.props.limited) {
              LearningObjectStore.incrementDownloadCount();
              this.props.onUpdate();
            }
          }}
          elementRef={btn => {
            if (btn) {
              if (learningObject.type === 'image') {
                btn.onclick = () => {
                  btn.href = toDataURL(url).then(dataUri => {
                    btn.onclick = () => {};
                    btn.href = dataUri;
                    btn.click();
                  }).catch(error => {
                    alertDialog({
                      message: formatMessage('Failed to download file. Please try again.'),
                    });
                    console.error(error);
                  });
                  return false;
                };
              }
              btn.setAttribute('download', learningObject.title);
            }
          }}
        >
          <Button
            renderIcon={IconDownload}
            display={Button.display.block}
            textAlign={Button.textAlign.start}
          >
            {formatMessage('Download')}
          </Button>
        </Link>
      </div>
    );
  }
}
