import React from 'react';
import PropTypes from 'prop-types';

// Actions
import AnalyticsActions from '../../actions/analytics';

// Components
import Results from './results';
import SearchInfo from './info';

export default class SearchObjects extends React.Component {
  static defaultProps = {
    baseParams: {}
  }

  static propTypes = {
    baseParams: PropTypes.object,
    fetchNext: PropTypes.func.isRequired,
    results: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    banner: PropTypes.node,
    emptyMessage: PropTypes.node.isRequired,
    statsMessage: PropTypes.node,
    searchPrivateObjects: PropTypes.bool,
    query: PropTypes.string,
    queryArialLabel: PropTypes.string
  }

  constructor (props) {
    super(props);
    this.state = {
      isNewSearch: true
    };
    this.props.fetchNext({
      ...props.baseParams,
      ...props.router.query
    });
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.state.isNewSearch && newProps.results.searchSuccessful) {
      this.setState({ isNewSearch: false });
      AnalyticsActions.viewedSearchResults(newProps.results.count);
    }

    if (this.props.router !== newProps.router) {
      const newParams = Object.assign(
        {},
        newProps.baseParams,
        newProps.router.query
      );
      this.props.fetchNext(newParams);
      this.setState({ isNewSearch: true });
    }
  }

  render () {
    const params = Object.assign(
      {},
      this.props.baseParams,
      this.props.router.query
    );
    const fetchNext = this.props.fetchNext.partial(params);
    const empty = this.props.results.store.showEmpty();
    const statsMessage = empty ? '' : this.props.statsMessage;

    return (
      <div>
        <SearchInfo {...this.props} statsMessage={statsMessage} />
        {empty ? (
          this.props.emptyMessage
        ) : (
          <Results {...this.props} fetchNext={fetchNext} />
        )}
      </div>
    );
  }
}
