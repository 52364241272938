import Uniflow from 'uniflow';
import SessionActions from '../actions/session';
import { heapInitializer, logoutHeapUser } from '../common/analytics/heap';

var SessionStore = Uniflow.createStore({
  isLoaded () {
    return this.state.retrievalSuccessful;
  },
  getExportData () {
    if (this.state.session.contentItems) {
      try {
        const exportData = JSON.parse(this.state.session.contentItems)['@graph'][0].placementOf;
        return {
          url: exportData['@id'],
          contentType: exportData.mediaType,
          courseId: +this.state.session.canvasCourseId || 0
        };
      } catch (e) {
        return null;
      }
    }
  },
  clearStore () {
    this.setState({
      session: {},
      isAuthenticated: false,
      limited: true,
      sid: null,
      isAdmin: false,
      userId: '',
      account: {
        id: '',
        name: '',
        uuid: ''
      },
      user: {
        id: '',
        uuid: '',
        fullName: '',
        avatarUrl: ''
      },
      returnUrl: '',
      type: '',
      contentItemUrl: '',
      publicOnly: false,
      retrievalPending: false,
      retrievalError: null,
      retrievalSuccessful: false
    });
  }
});

SessionStore.clearStore();

SessionActions.on('session-retrieval-pending', () => {
  SessionStore.setState({
    retrievalPending: true,
    retrievalSuccessful: false,
    retrievalError: null
  });
});

SessionActions.on('session-retrieval-error', (error) => {
  logoutHeapUser();
  SessionStore.setState({
    retrievalError: error,
    retrievalPending: false,
    retrievalSuccessful: false
  });
});

SessionActions.on('session-retrieval-success', (session) => {
  var contentItemUrl;
  try {
    contentItemUrl = JSON.parse(session.contentItems)['@graph'][0].placementOf['@id'];
  } catch (err) {
    contentItemUrl = undefined;
  }

  SessionStore.setState({
    ...session,
    session,
    isAuthenticated: true,
    limited: !session.accountId,
    account: {
      id: session.accountId,
      name: session.accountName,
      uuid: session.canvasRootAccountUuid
    },
    user: {
      id: session.userId,
      uuid: session.canvasUserUuid,
      fullName: session.userFullName,
      avatarUrl: session.userAvatarUrl
    },
    type: session.contextType,
    contentItemUrl,
    publicOnly: Boolean(session.publicOnly),
    retrievalPending: false,
    retrievalError: null,
    retrievalSuccessful: true
  });

  heapInitializer(
    session.usageMetricsEnabled === true,
    session.heapUserId,
    session.heapUserProps,
    session.heapCommonEventProps
  );
});

SessionActions.on('cancel-share', () => {
  SessionStore.setState({
    contextType: '',
    returnUrl: '',
    type: '',
    contentItemUrl: '',
    contentItems: '',
    sourceId: '',
    updateResourceId: '',
    resourceTitle: '',
    session: {
      ...SessionStore.state.session,
      contextType: '',
      returnUrl: '',
      type: '',
      contentItemUrl: '',
      contentItems: '',
      sourceId: '',
      updateResourceId: '',
      resourceTitle: ''
    }
  });
});

SessionActions.on('session-clear', () => {
  SessionStore.clearStore();
  logoutHeapUser();
});

export default SessionStore;
