import formatMessage from 'format-message';
import React from 'react';
import Head from 'react-helmet';
import AppHandler from '../components/app-handler';
import EditForm from '../components/resources/forms/edit';
import { ResourceActions, ConsortiumsActions, SessionActions } from '../actions';
import { getState, subscribe } from '../store';
import PageTitle from '../components/common/page-title';

/* eslint-disable react/prop-types */
const view = resourceId => {
  const state = getState();

  return (
    <AppHandler
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <Head
        title={formatMessage('Edit { title }', {
          title: state.resource.learningObject.title
        })}
      />
      <PageTitle title={formatMessage('Edit Resource')} />
      <EditForm
        {...state}
        learningObjectId={resourceId}
        groups={state.groups.list}
      />
    </AppHandler>
  );
};

export default async function ({ resolve, params, render, exiting }) {
  ResourceActions.clearStore();
  ResourceActions.find(params.id);
  ConsortiumsActions.loadList();

  render(view(params.id));
  resolve();
  const unsubscribe = subscribe(() => render(view(params.id)));
  exiting.then(unsubscribe);
}
