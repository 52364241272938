import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Select extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  }

  render () {
    return (
      <select
        {...this.props}
        className={classnames('Select', this.props.className)}
      >
        {this.props.children}
      </select>
    );
  }
}
