import formatMessage from 'format-message';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useMedia from '../common/use-media';
import UpdatesActions from '../actions/updates';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';
import IconArrowOpenDownSolid from '@instructure/ui-icons/lib/IconArrowOpenDownSolid';
import { AppNav } from '@instructure/ui-navigation/lib/AppNav';
import { ApplyTheme } from '@instructure/ui-themeable/lib/ApplyTheme';
import { Text } from '@instructure/ui-text/lib';
import { Help } from '../components/help';
import { HeaderTail } from './app-header-tail';
import NewAppNav from './app-nav/new-app-nav';
import { getNavItems, mapTitleToHeader } from './app-header-utils';
import { getState } from '../store';

const AppHeaderHead = ({ shouldShowBigMenu, isAuthenticated }) => {
  if (!shouldShowBigMenu && isAuthenticated) {
    return null;
  }

  const textSize = shouldShowBigMenu ? 'x-large' : 'medium';
  const padding = shouldShowBigMenu ? '1.5rem 0' : null;
  const margin = shouldShowBigMenu ? '0px 0px 0px 12px' : null;

  return (
    <ApplyTheme
      theme={{
        [AppNav.Item.theme]: {
          padding
        }
      }}
    >
      <div
        style={{
          margin
        }}
      >
        <AppNav.Item
          renderLabel={<ScreenReaderContent>Commons</ScreenReaderContent>}
          renderIcon={
            <Text color="primary" weight="light" size={textSize}>
              Commons
            </Text>
          }
          href="/search"
          data-testid="AppHeaderLogo"
        />
      </div>
    </ApplyTheme>
  );
};

export const AppHeader = ({
  session,
  activeHeader,
  isManager,
  updates,
  logout
}) => {
  const { userId, isAuthenticated, isAdmin, allowApprovedContent, isAccountCurator, limited: isSessionLimited, userFullName, userAvatarUrl, retrievalSuccessful, publicOnly } = session;
  const [mainHeader, setMainHeader] = useState('search');
  const [guidesOpen, setGuidesOpen] = useState(false);
  const [shouldShowAppNavItems, setShouldShowAppNavItems] = useState(true);
  const useICEEMenus = _.get(getState(), 'featureFlags.flags.use_icee_menus');
  const dontUseICEEMenus = _.get(getState(), 'featureFlags.flags.dont_use_icee_menus');
  const isInIframe = window !== window.top;

  useEffect(() => {
    if (activeHeader) {
      setMainHeader(activeHeader);
    }
  }, [activeHeader]);

  useEffect(() => {
    if (userId && userId !== updates.userId) {
      UpdatesActions.getUpdates(userId);
    }
  }, []);

  const items = isAuthenticated
    ? getNavItems({
      isAdmin,
      allowApprovedContent,
      isAccountCurator,
      isManager,
      isSessionLimited,
      updates,
      publicOnly,
    }) : [];

  const shouldShowBigMenu = useMedia(['(min-width: 684px)'], [true], false);

  const openGuides = () => setGuidesOpen(true);
  const closeGuides = () => setGuidesOpen(false);
  const headerTailProps = {
    isAuthenticated,
    isInIframe,
    retrievalSuccessful,
    userFullName,
    userAvatarUrl,
    logout,
    openGuides,
    shouldShowBigMenu
  };

  return (
    <React.Fragment>
      <Help open={guidesOpen} onClose={closeGuides} />
      {!isInIframe && !shouldShowBigMenu && isAuthenticated && (
        <div className="App-header-title">
          <span>Commons</span>
        </div>
      )}
      {
        useICEEMenus && !dontUseICEEMenus ? (
          <NewAppNav
            navItems={items}
            activeHeader={activeHeader}
            mainHeader={mainHeader}
            session={session}
            isInFrame={isInIframe}
            retrievalSuccessful={retrievalSuccessful}
            logout={logout}
            openGuides={openGuides}
          />
        ) : (
          <ApplyTheme
            theme={{
              [AppNav.Item.theme]: {
                fontSize: shouldShowBigMenu ? '1.125rem' : '1rem'
              },
              [AppNav.theme]: {
                height: shouldShowBigMenu ? '3.75rem' : '3rem'
              }
            }}
          >
            <AppNav
              screenReaderLabel={formatMessage('Commons navigation')}
              visibleItemsCount={shouldShowAppNavItems ? items.length : 0}
              onUpdate={({ visibleItemsCount }) => {
                setShouldShowAppNavItems(shouldShowBigMenu && visibleItemsCount === items.length);
              }}
              debounce={0}
              renderBeforeItems={
                <AppHeaderHead
                  shouldShowBigMenu={shouldShowBigMenu}
                  isAuthenticated={isAuthenticated}
                />
              }
              renderAfterItems={<HeaderTail {...headerTailProps} />}
              renderTruncateLabel={() => {
                if (!shouldShowAppNavItems) {
                  return (
                    <span className="App-header-truncate-menu" data-testid="AppHeaderTruncateMenu">
                      <IconArrowOpenDownSolid size="x-small" />
                      <span className="App-header-truncate-current-location">
                        {mapTitleToHeader[mainHeader]()}
                      </span>
                    </span>
                  );
                }
              }}
              data-testid="AppNav"
            >
              {items.map((item) => {
                return (<AppNav.Item
                  href={item.path}
                  isSelected={activeHeader === item.id}
                  renderLabel={item.label}
                  key={item.id}
                />);
              })}
            </AppNav>
          </ApplyTheme>
        )
      }
    </React.Fragment>
  );
};

AppHeader.propTypes = {
  session: PropTypes.object.isRequired,
  activeHeader: PropTypes.oneOf(Object.keys(mapTitleToHeader)),
  isManager: PropTypes.bool,
  updates: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};
