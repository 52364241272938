import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import uniflowComponent from 'uniflow-component';
import formatMessage from 'format-message';
import ButtonConfirm from '../common/button-confirm';
import { UpdatesActions } from '../../actions';
import UpdatesStore from '../../stores/updates';
import SessionStore from '../../stores/session';

export const DismissButton = createReactClass({
  displayName: 'DismissButton',

  getInitialState () {
    return {
      dismissing: false
    };
  },

  propTypes: {
    onDismissal: PropTypes.func,
    children: PropTypes.node,
    learningObjectId: PropTypes.string.isRequired,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string
      })
    ).isRequired,
    updates: PropTypes.object.isRequired,
    sessionState: PropTypes.object.isRequired
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.updates.ignoreForCoursesPending) {
      this.setState({ dismissing: false });
    }
  },

  handleAccept () {
    if (this.props.onDismissal) { this.props.onDismissal(this.props.learningObjectId); }
    UpdatesActions.ignoreUpdateForCourses(
      this.props.sessionState.user.id,
      this.props.learningObjectId,
      this.props.courses.map(course => course.id)
    );
    this.setState({ dismissing: true });
  },

  render () {
    var prompt = formatMessage(`
      Ignore this update for these courses?
      You will still be notified of future updates to this resource.
    `);

    return (
      <div className="DismissButton">
        <ButtonConfirm
          ref="menu"
          buttonClassName="DismissUpdate-button"
          onAccept={this.handleAccept}
          prompt={prompt}
          disabled={this.state.dismissing || !this.props.courses.length}
        >
          {this.state.dismissing
            ? formatMessage('Dismissing...')
            : this.props.children}
        </ButtonConfirm>
      </div>
    );
  }
});

export default uniflowComponent(DismissButton, {
  updates: UpdatesStore,
  sessionState: SessionStore
});
