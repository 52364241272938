import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import Tos from '../components/tos';
import AppHandler from '../components/app-handler';
import { getState, subscribe } from '../store';
import SessionActions from '../actions/session';

const view = () => {
  const state = getState();

  return (
    <div>
      <Head title={formatMessage('Terms of Service')} />
      <AppHandler
        activeHeader="tos"
        session={state.session}
        updates={state.updates}
        isManager={state.groups.isManager}
        logout={SessionActions.logout}
      >
        <Tos />
      </AppHandler>
    </div>
  );
};

export default function ({ resolve, render, exiting }) {
  render(view());
  resolve();

  const unsubscribe = subscribe(() => render(view()));
  exiting.then(unsubscribe);
}
