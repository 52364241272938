import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

// Components
import FavoriteResourcesList from './favorite-resources-list';
import NoResultsQuote from '../common/no-results-quote';
import starUrl from '../../../assets/svg/star-flag.svg';
import noResultsUrl from '../../../assets/svg/no_result.svg';

// Actions
import SearchActions from '../../actions/search';

// Stores
import ResultsStore from '../../stores/results';
import { resourceTypes } from '../../common/app-constants';
import FavoriteResourceImportStatusScreenReaderContent from './favorite-resource-import-status-screenreadercontent';

const getEmptyMessage = ({ types, q }) => {
  const resourceType = resourceTypes[types];
  if (q) {
    return formatMessage('No Results Found');
  }
  if (!resourceType) {
    return formatMessage('Currently No Favorites');
  }
  return formatMessage('No Favorite {type}', {
    type: resourceTypes[types].pluralName
  });
};

const getQuote = ({ types, q }) => {
  if (q) {
    return null;
  }
  return formatMessage(
    'Add favorites from Commons by clicking the "Add to Favorites" link in the Resource details page.'
  );
};

const getNoResultProps = query => {
  const { q } = query;
  return {
    className: q ? '' : 'small',
    photoUrl: q ? noResultsUrl : starUrl,
    message: getEmptyMessage(query),
    quote: getQuote(query),
    photoAlt: formatMessage('Icon')
  };
};

const fetchFavoritesTrayResults = (params, cursor) => SearchActions.fetchResultsWithPath(params, cursor, '/resources/favorites-tray');

export default class FavoriteResourcesHandler extends React.Component {
  static displayName = 'FavoriteResourcesHandler';

  static propTypes = {
    session: PropTypes.object,
    favorites: PropTypes.object
  };

  render () {
    const {
      router,
      importStatus
    } = this.props;

    const noResultsProps = getNoResultProps(router.query);

    return (
      <React.Fragment>
        <FavoriteResourceImportStatusScreenReaderContent importStatus={importStatus} />
        <FavoriteResourcesList
          baseParams={{
            onlyFavorites: true
          }}
          fetchNext={fetchFavoritesTrayResults}
          queryArialLabel={formatMessage(
            'Search My Favorited Resources for course resources by keyword, author, institution, or title'
          )}
          isLoading={ResultsStore.showLoading()}
          showFavoriteOptions
          emptyMessage={<NoResultsQuote {...noResultsProps} />}
          canShowPublic={!this.props.session.publicOnly}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
