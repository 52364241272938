import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import formatMessage from 'format-message';
import * as announce from '../../../common/announce';
import AccountGroupsSearch from './search';
import AccountGroupsResult from './result';
import AccountGroupsCreate from './create';
import NoResults from './no-results';

export default createReactClass({
  displayName: 'AccountGroups',

  propTypes: {
    createName: PropTypes.string,
    changeCreateName: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    createGroupPending: PropTypes.bool,
    deleteGroup: PropTypes.func.isRequired,
    list: PropTypes.array,
    filter: PropTypes.string,
    filterList: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired
  },

  handleSearchChange (event) {
    this.props.filterList(event.target.value);
  },

  createGroupFilter () {
    const terms = this.props.filter
      .trim()
      .toLowerCase()
      .split(/\s+/);
    if (terms.length === 0) {
      return () => true;
    }
    return ({ name }) => terms.every(term => name.toLowerCase().includes(term));
  },

  deleteGroupHandler (group) {
    const groupElement = ReactDOM.findDOMNode(this.refs[group.id]);
    let elementToFocus = null;

    if (groupElement.nextSibling) {
      elementToFocus = groupElement.nextSibling.querySelector(
        '.AccountGroupsResult-link'
      );
    } else if (groupElement.previousSibling) {
      elementToFocus = groupElement.previousSibling.querySelector(
        '.AccountGroupsResult-link'
      );
    } else {
      elementToFocus = document.querySelector('.AccountGroupsCreate-input');
    }

    elementToFocus.focus();
    this.props.deleteGroup(group);
  },

  renderGroup (group) {
    if (this.props.session.isAdmin || group.isManager) {
      return (
        <AccountGroupsResult
          key={group.id}
          ref={group.id}
          group={group}
          session={this.props.session}
          onDelete={this.deleteGroupHandler}
        />
      );
    }
  },

  render () {
    const { list, filter, session } = this.props;
    const filtered = list.filter(this.createGroupFilter());
    announce.politely(
      formatMessage(
        `{count, plural,
      one {# group found}
      other {# groups found}
    }`,
        {
          count: filtered.length
        }
      )
    );
    const hasNoResults = list.length > 0 && filtered.length === 0;
    const canCreateGroup = session.isAdmin;

    return (
      <div>
        <AccountGroupsSearch value={filter} onChange={this.handleSearchChange} />
        <div className="AccountGroups-table">
          <div className="AccountGroups-results-header">
            <div className="AccountGroups-results-header-name">
              {formatMessage('Name')}
            </div>
          </div>
          {canCreateGroup && (
            <AccountGroupsCreate
              isCreating={this.props.createGroupPending}
              name={this.props.createName}
              onChange={this.props.changeCreateName}
              onCreate={this.props.createGroup}
            />
          )}
          {hasNoResults ? (
            <NoResults />
          ) : (
            <div className="AccountGroups-results">
              {filtered.map(this.renderGroup)}
            </div>
          )}
        </div>
      </div>
    );
  }
});
