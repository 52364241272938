import React from 'react';
import PropTypes from 'prop-types';
import IconTrash from '@instructure/ui-icons/lib/IconTrashLine';
import formatMessage from 'format-message';

import { confirmationDialog } from '../confirmation-dialog';
import LearningObjectActions from '../../actions/resource';
import Link from '../common/link';

class LearningObjectDeleteButton extends React.Component {
  constructor (props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  async onClick () {
    const message = formatMessage('You will permanently remove {name} from Commons.', {
      name: this.props.learningObjectTitle
    });
    const title = formatMessage('Remove {name}?', {
      name: this.props.learningObjectTitle
    });
    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Remove')
    });

    if (confirmationAnswer) {
      // eslint-disable-line
      LearningObjectActions.destroyById(this.props.learningObjectId);
    }
  }

  render () {
    return (
      <Link
        className="lor-delete-button"
        renderIcon={IconTrash}
        onClick={this.onClick}
      >
        {formatMessage('Remove from Commons')}
      </Link>
    );
  }
}

LearningObjectDeleteButton.propTypes = {
  className: PropTypes.string,
  learningObjectId: PropTypes.string.isRequired,
  learningObjectTitle: PropTypes.string.isRequired
};

export default LearningObjectDeleteButton;
