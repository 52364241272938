import React from 'react';
import { Heading } from '@instructure/ui-heading';
import PropTypes from 'prop-types';

const PageTitle = ({ title, margin }) =>
  <Heading
    margin={margin || '0 0 large'}
    level="h1"
  >
    {title}
  </Heading>;

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  margin: PropTypes.string
};

export default PageTitle;
