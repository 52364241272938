import Uniflow from 'uniflow';
import LicensesActions from '../actions/licenses';

var LicensesStore = Uniflow.createStore({
  getLicenseWithId (id) {
    return this.state.licenses.find((license) => license.id === id);
  },
  state: {
    licenses: [],
    retrievalPending: false,
    retrievalError: null,
    retrievalSuccessful: false
  }
});

// pending
LicensesActions.on('licenses-retrieval-pending', () => {
  LicensesStore.setState({
    retrievalPending: true,
    retrievalSuccessful: false,
    retrievalError: null
  });
});

// errors
LicensesActions.on('licenses-retrieval-error', (error) => {
  LicensesStore.setState({
    retrievalError: error,
    retrievalPending: false,
    retrievalSuccessful: false
  });
});

// successes
LicensesActions.on('licenses-retrieval-success', ({ items }) => {
  LicensesStore.setState({
    licenses: items,
    retrievalPending: false,
    retrievalSuccessful: true,
    retrievalError: null
  });
});

export default LicensesStore;
