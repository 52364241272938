import formatMessage from 'format-message';

const STOCK_PREFIX = 'http://lor-images.s3.amazonaws.com/subjects/';

const STOCK_THUMBNAILS = {
  'english-language-arts/English-01-262x147.jpg': formatMessage('Writing in notebook'),
  'english-language-arts/English-02-262x147.jpg': formatMessage('Shakespeare monument'),
  'english-language-arts/English-03-262x147.jpg': formatMessage('Antique brass door lock key'),
  'english-language-arts/English-04-262x147.jpg': formatMessage('Writer’s desk including laptop'),
  'english-language-arts/English-05-262x147.jpg': formatMessage('Close up of fountain pen on old letter'),
  'english-language-arts/English-06-262x147.jpg': formatMessage('Woman writing in notebook, top view'),
  'english-language-arts/English-07-262x147.jpg': formatMessage('Man writing in notebook'),
  'english-language-arts/English-08-262x147.jpg': formatMessage('Stone arch bridge over clear stream'),
  'english-language-arts/English-09-262x147.jpg': formatMessage('Man waiting while train passes'),
  'english-language-arts/English-10-262x147.jpg': formatMessage('Sea of umbrellas with green highlight'),
  'english-language-arts/English-11-262x147.jpg': formatMessage('Zeus statue on fountain Navona square'),
  'english-language-arts/English-12-262x147.jpg': formatMessage('Details of Duomo in Milan Cathedral'),
  'math/Math-01-262x147.jpg': formatMessage('Person working on math problems with pencil and paper'),
  'math/Math-02-262x147.jpg': formatMessage('Close up of nautilus shell'),
  'math/Math-03-262x147.jpg': formatMessage('Close up of astronomical clock'),
  'math/Math-04-262x147.jpg': formatMessage('Engineers over blueprint in meeting room'),
  'math/Math-05-262x147.jpg': formatMessage('Algebra equations on a chalkboard'),
  'math/Math-06-262x147.jpg': formatMessage('Satellites in orbit over earth'),
  'math/Math-07-262x147.jpg': formatMessage('Mathematical equations handwritten on notebook page with slide ruler'),
  'math/Math-08-262x147.jpg': formatMessage('Close up of spiral green leaf'),
  'math/Math-09-262x147.jpg': formatMessage('Bridge cables pattern'),
  'math/Math-10-262x147.jpg': formatMessage('Inside workings of a clock'),
  'math/Math-11-262x147.jpg': formatMessage('View of galaxy cluster of stars in outer space'),
  'math/Math-12-262x147.jpg': formatMessage('Close up of plant stems'),
  'science/Science-01-262x147.jpg': formatMessage('Lady bug climbing stem in wheat field'),
  'science/Science-02-262x147.jpg': formatMessage('Silhouette of satellite dishes at sunset'),
  'science/Science-03-262x147.jpg': formatMessage('Scientists inspecting plants in petri dishes on a glass table'),
  'science/Science-04-262x147.jpg': formatMessage('Professional young woman IT professional'),
  'science/Science-05-262x147.jpg': formatMessage('Female hand holding hand over scientific article'),
  'science/Science-06-262x147.jpg': formatMessage('Close up of a yellow seahorse'),
  'science/Science-07-262x147.jpg': formatMessage('Distant galaxy and stars from outer space'),
  'science/Science-08-262x147.jpg': formatMessage('Close up of microscope'),
  'science/Science-09-262x147.jpg': formatMessage('Close up of semi transparent leaf'),
  'science/Science-10-262x147.jpg': formatMessage('Spider web with water droplets stuck to webbing'),
  'science/Science-11-262x147.jpg': formatMessage('Close up of category 5 network board'),
  'science/Science-12-262x147.jpg': formatMessage('Close up of scientific lab equipment'),
  'specialty/specialty-01-262x147.jpg': formatMessage('Beach at sunset'),
  'specialty/specialty-02-262x147.jpg': formatMessage('3d rendering of Molecular Structure'),
  'specialty/specialty-03-262x147.jpg': formatMessage('Fiber optics abstract in blue'),
  'specialty/specialty-04-262x147.jpg': formatMessage('Abstract view of light moving through a tunnel'),
  'specialty/specialty-05-262x147.jpg': formatMessage('Architecture Blueprint-Mechanical Engineering Blueprint'),
  'specialty/specialty-06-262x147.jpg': formatMessage('Close up of scientific lab equipment'),
  'specialty/specialty-07-262x147.jpg': formatMessage('Drawing a structural formula of a molecule onto a blackboard'),
  'specialty/specialty-08-262x147.jpg': formatMessage('View of earth from outer space'),
  'specialty/specialty-09-262x147.jpg': formatMessage('Close up view of spiral shell'),
  'specialty/specialty-10-262x147.jpg': formatMessage('Close up of green leaf with water droplets'),
  'specialty/specialty-11-262x147.jpg': formatMessage('Close up of bubbles in liquid'),
  'specialty/specialty-12-262x147.jpg': formatMessage('Tree frog resting on branch'),
  'social-studies/SocialStudies-01-262x147.jpg': formatMessage('Inside view of Elks Rotunda Chicago'),
  'social-studies/SocialStudies-02-262x147.jpg': formatMessage('Buddhist culture in Asia'),
  'social-studies/SocialStudies-03-262x147.jpg': formatMessage('View of beach and buildings Polignano a mare, Southern Italy'),
  'social-studies/SocialStudies-04-262x147.jpg': formatMessage('Columns covered in hieroglyphics, Karnak, Egypt'),
  'social-studies/SocialStudies-05-262x147.jpg': formatMessage('Abstract networking image of people on sphere'),
  'social-studies/SocialStudies-06-262x147.jpg': formatMessage('Mayan Temple at Yaxhá'),
  'social-studies/SocialStudies-07-262x147.jpg': formatMessage('Moroccan palace arches'),
  'social-studies/SocialStudies-08-262x147.jpg': formatMessage('Sunset silhouette longtail boat Thailand'),
  'social-studies/SocialStudies-09-262x147.jpg': formatMessage('Colosseum at sunset in Rome, Italy'),
  'social-studies/SocialStudies-10-262x147.jpg': formatMessage('The Arch of Constantine at dusk (Rome)'),
  'social-studies/SocialStudies-11-262x147.jpg': formatMessage('Internal view of Blue Mosque, Sultanahmet, Istanbul, Turkey'),
  'social-studies/SocialStudies-12-262x147.jpg': formatMessage('Chalkboard drawing of people and networking'),
  'arts-and-music/Arts-01-262x147.jpg': formatMessage('Body of violin resting on open score'),
  'arts-and-music/Arts-02-262x147.jpg': formatMessage('Spiral staircase from below'),
  'arts-and-music/Arts-03-262x147.jpg': formatMessage('Dance floor and feet of couple dancing'),
  'arts-and-music/Arts-04-262x147.jpg': formatMessage('Close up of artist working on ceramic vase on spinning wheel'),
  'arts-and-music/Arts-05-262x147.jpg': formatMessage('Abstract close up of colored papers in interesting pattern'),
  'arts-and-music/Arts-06-262x147.jpg': formatMessage('View of ballet dancers feet'),
  'arts-and-music/Arts-07-262x147.jpg': formatMessage('Abstract fine art painting'),
  'arts-and-music/Arts-08-262x147.jpg': formatMessage('Photo of Roman relief sculpture of a Male Captive'),
  'arts-and-music/Arts-09-262x147.jpg': formatMessage('View of artist’s hands while painting'),
  'arts-and-music/Arts-10-262x147.jpg': formatMessage('Close up of acoustical guitar and musician’s hands'),
  'arts-and-music/Arts-11-262x147.jpg': formatMessage('Close up of tribal type drum'),
  'arts-and-music/Arts-12-262x147.jpg': formatMessage('Close up of sound board switches and dials'),
  'foreign-language/Foreign-01-262x147.jpg': formatMessage('Close up of statue of Buddha'),
  'foreign-language/Foreign-02-262x147.jpg': formatMessage('Taj Mahal in Agra India'),
  'foreign-language/Foreign-03-262x147.jpg': formatMessage('Two women speaking with each other'),
  'foreign-language/Foreign-04-262x147.jpg': formatMessage('Architectural details of St. Basil’s Cathedral'),
  'foreign-language/Foreign-05-262x147.jpg': formatMessage('Close up of handwritten letter with eyeglasses'),
  'foreign-language/Foreign-06-262x147.jpg': formatMessage('View of the Great Wall of China'),
  'foreign-language/Foreign-07-262x147.jpg': formatMessage('Silk lanterns in Hoi An City, Vietnam'),
  'foreign-language/Foreign-08-262x147.jpg': formatMessage('Ground up view of the Eiffel Tower in Paris'),
  'foreign-language/Foreign-09-262x147.jpg': formatMessage('Golden Pavilion and Lotus Pond - Nan Lian Garden, Hong Kong'),
  'foreign-language/Foreign-10-262x147.jpg': formatMessage('View of alley in European country with red walls'),
  'foreign-language/Foreign-11-262x147.jpg': formatMessage('Temple in Thailand'),
  'foreign-language/Foreign-12-262x147.jpg': formatMessage('Boudhanath Stupa, Kathmandu, Nepal')
};

export function getDescription (url, description) {
  if (url.slice(0, STOCK_PREFIX.length) === STOCK_PREFIX) {
    const path = url.slice(STOCK_PREFIX.length);
    if (STOCK_THUMBNAILS[path]) return STOCK_THUMBNAILS[path];
  }
  // eslint-disable-next-line no-useless-escape
  if (/\/api\/media\/[^\/]+\/thumbnail/.test(url)) {
    return formatMessage('A thumbnail of the video.');
  }
  return description;
}

export function localizeDescription (object) {
  if (object && object.thumbnail) {
    object.thumbnail.description = (object.type === 'image')
      ? formatMessage('Cropped thumbnail of shared image')
      : getDescription(object.thumbnail.url, object.thumbnail.description);
  }
  return object;
}
