import React from 'react';
import PropTypes from 'prop-types';
import IconArrowOpenStart from '@instructure/ui-icons/lib/IconArrowOpenStartLine';
import formatMessage from 'format-message';

import Link from '../common/link';
import Button from '../common/button';

export default class LearningObjectBackButton extends React.Component {
  render () {
    const { name = formatMessage('Search'), uri = '/search' } =
      this.props.backTo || {};

    return (
      <Link href={uri}>
        <Button
          size={Button.size.small}
          renderIcon={IconArrowOpenStart}
          className="lor-back-button lor-instui-simple-button"
        >
          {formatMessage('Back to {name}', { name })}
        </Button>
      </Link>
    );
  }
}

LearningObjectBackButton.propTypes = {
  backTo: PropTypes.object
};
