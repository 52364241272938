import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Pill } from '@instructure/ui-pill/lib/Pill';
import classnames from 'classnames';

const CommonsPill = (props) => {
  const { ref, className, disabled, ariaLabel, elementRef } = {
    className: '',
    ...props
  };
  const innerProperties = { ref };
  const classNames = classnames(
    ...className.split(' '),
    'Pill',
  );
  const addAttributes = useCallback((pill) => {
    if (className) {
      pill.classList.add(...classNames.split(' '));
    }

    if (disabled) {
      pill.setAttribute('aria-disabled', disabled);
    }

    if (ariaLabel) {
      pill.setAttribute('aria-label', ariaLabel);
    }
  }, [className, disabled, ariaLabel]);

  return (
    <Pill
      {...props}
      elementRef={pill => pill && addAttributes(pill) && elementRef && elementRef(pill)}
    >
      {ref ? (
        <span {...innerProperties}>
          {props.children}
        </span>
      ) : props.children}
    </Pill>
  );
};

CommonsPill.color = {
  primary: 'primary',
  success: 'success',
  danger: 'danger',
  info: 'info',
  warning: 'warning',
  alert: 'alert',
};

CommonsPill.propTypes = {
  color: PropTypes.oneOf(Object.keys(CommonsPill.color)),
  margin: PropTypes.string,
  textAlign: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  elementRef: PropTypes.func
};

export default CommonsPill;
