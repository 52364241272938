import range from 'lodash/utility/range';
import apiRequest from '../common/api-request';
import RouterActions from '../actions/router';
import env from '../config/env';
import { localizeDescription } from './thumbnail-get-description';
import localizeErrors from './error-messages/resources';
import isErroneous from '../common/is-erroneous';
import isUnauthorized from '../common/is-unauthorized';
import omit from 'lodash/object/omit';

var NUM_STARS = 5;

var lastFindAllRequest = null;
export function findAll (criteria, done) {
  const request = apiRequest({
    path: '/resources',
    query: (criteria && criteria.where) || {},
    bodyTransform: body => {
      // TODO: make more functional (use map instead of forEach)
      (body.items || []).forEach(localizeDescription);
      return body;
    }
  });

  if (lastFindAllRequest && lastFindAllRequest.xhr) {
    lastFindAllRequest.abort();
  }

  lastFindAllRequest = request.end((err, res) => {
    if (isUnauthorized(err)) {
      return RouterActions.transitionTo('/errors/not-authenticated');
    }

    if (isErroneous(err, res)) {
      localizeErrors(err);
      return done(err, res ? res.body : null);
    }

    done(null, res.body);
  });

  return lastFindAllRequest;
}

export function create (data, done) {
  const request = apiRequest({
    method: 'post',
    path: '/resources',
    bodyTransform: localizeDescription,
    body: data
  });

  return request.end((err, res) => {
    if (isUnauthorized(err)) {
      return RouterActions.transitionTo('/errors/not-authenticated');
    }
    if (isErroneous(err, res)) {
      localizeErrors(err);
      return done(err);
    }

    // TODO: might want to change these to return body instead
    // of model to match other app conventions
    done(err, { status: res.status, model: res ? res.body : null });
  });
}

export function update (model, done) {
  const resourceModel = omit(model, ['courseOutlines', 'versions']);

  const request = apiRequest({
    method: 'put',
    path: `/resources/${model.id}`,
    body: resourceModel
  });

  return request.end((err, res) => {
    if (isUnauthorized(err)) {
      return RouterActions.transitionTo('/errors/not-authenticated');
    }
    if (isErroneous(err, res)) {
      localizeErrors(err);
      return done(err);
    }

    // TODO: might want to change these to return body instead
    // of model to match other app conventions
    done(null, { status: res.status, model: res ? res.body : null });
  });
}

export function updateWithNewVersion (model, done) {
  const resourceModel = omit(model, ['courseOutlines', 'versions']);
  const request = apiRequest({
    method: 'put',
    path: `/resources/${model.id}/new-version`,
    body: resourceModel
  });

  return request.end((err, res) => {
    if (isUnauthorized(err)) {
      return RouterActions.transitionTo('/errors/not-authenticated');
    }
    if (isErroneous(err, res)) {
      localizeErrors(err);
      return done(err, null);
    }

    // TODO: might want to change these to return body instead
    // of model to match other app conventions
    done(err, { status: res.status, model: res ? res.body : null });
  });
}

export function destroy (id, done) {
  const request = apiRequest({
    method: 'delete',
    path: `/resources/${id}`
  });

  return request.end((err, res) => {
    if (isUnauthorized(err)) {
      return RouterActions.transitionTo('/errors/not-authenticated');
    }
    if (isErroneous(err, res)) {
      localizeErrors(err);
      return done(err, null);
    }

    done(err, { status: res.status, body: res.body });
  });
}

export function importIntoCourse ({ learningObjectId, courses, target }, done) {
  const request = apiRequest({
    method: 'post',
    path: `/resources/${learningObjectId}/import`,
    body: { courses, target }
  });

  return request.end((err, res) => {
    if (isUnauthorized(err)) {
      return RouterActions.transitionTo('/errors/not-authenticated');
    }
    if (isErroneous(err, res)) {
      return done(err, null);
    }

    done(err, res);
  });
}

function ratingCountAttribute (count) {
  return 'count' + count + 'StarRatings';
}

function sumRatingValues (learningObject) {
  if (!learningObject) {
    return 0;
  }

  return range(1, NUM_STARS + 1).reduce((memo, star) => {
    var starCount = learningObject[ratingCountAttribute(star)];
    memo += starCount ? star * parseInt(starCount, 10) : 0;
    return memo;
  }, 0);
}

function countNumberOfRatings (learningObject) {
  if (!learningObject) {
    return 0;
  }

  return range(1, NUM_STARS + 1).reduce((memo, star) => {
    var starCount = learningObject[ratingCountAttribute(star)];
    memo += starCount ? parseInt(starCount, 10) : 0;
    return memo;
  }, 0);
}

export function getRatingsCount (learningObject) {
  if (!learningObject) {
    return null;
  }

  return countNumberOfRatings(learningObject);
}

export function getRatingsAverage (learningObject, decimalPlaces) {
  if (!learningObject) {
    return null;
  }

  decimalPlaces = parseInt(decimalPlaces, 10) || 0;
  var multiplier = Math.pow(10, decimalPlaces);

  var totalRatings = countNumberOfRatings(learningObject);

  return totalRatings > 0
    ? Math.round(
      (sumRatingValues(learningObject) / totalRatings) * multiplier
    ) / multiplier
    : 0;
}

export function getDownloadUrl (learningObjectId) {
  return `/api/resources/${learningObjectId}/download?session-id=${
    env.SESSION_ID
  }`;
}

export function getImagePreviewUrl (learningObjectId) {
  return `/api/resources/${learningObjectId}/image-preview?session-id=${
    env.SESSION_ID
  }`;
}
