import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FilterIcon = ({ className, ...restProps }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    className={classnames('FilterIcon', className)}
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    role="img"
    {...restProps}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-99.000000, -167.000000)" fill="#384A58">
        <polygon points="99 167.000103 105.955977 176.232676 105.955977 182.547359 110.668458 184.903599 110.668458 176.232676 116.99969 167.82758 116.173247 167.205938 109.634112 175.88617 109.634112 183.230027 106.990323 181.908133 106.990323 175.88617 101.074898 168.034449 112.908851 168.034449 112.908851 167.000103" />
      </g>
    </g>
  </svg>
);

FilterIcon.displayName = 'FilterIcon';
FilterIcon.propTypes = {
  className: PropTypes.string
};

export default FilterIcon;
