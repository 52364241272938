import React from 'react';
import Head from 'react-helmet';
import AppHandler from '../components/app-handler';
import LearningResource from '../components/resources';

import { ResourceActions, SessionActions } from '../actions/';
import { getState, subscribe } from '../store';

/* eslint-disable react/prop-types */
const view = (id, tab, location = window.location) => {
  const state = getState();

  return (
    <AppHandler
      session={state.session}
      updates={state.updates}
      logout={SessionActions.logout}
      isManager={state.groups.isManager}
    >
      <Head title={state.resource.learningObject.title} />
      <LearningResource
        learningObjectId={id}
        {...state}
        tab={tab}
        reviews={state.resource.reviews}
      />
    </AppHandler>
  );
};

export default async function ({ params, resolve, exiting, render, location }) {
  const isAlreadyLoaded = getState().resource.learningObject.id === params.id;
  if (!isAlreadyLoaded) {
    setTimeout(() => {
      ResourceActions.find(params.id);
    }, 0);
  }

  ResourceActions.getReviews(params.id);

  render(view(params.id, params.tab, location));
  resolve();

  const unsubscribe = subscribe(() => render(view(params.id, params.tab)));
  exiting.then(unsubscribe);
}
