import React from 'react';
import formatMessage from 'format-message';
import { Spinner } from '@instructure/ui-spinner/lib';

export default class Loading extends React.Component {
  render () {
    return (
      <div {...this.props}>
        <span className="screenreader-only">{formatMessage('Loading...')}</span>
        <Spinner size="small" renderTitle={formatMessage('Loading...')} />
      </div>
    );
  }
}
