import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

import IconCourses from '@instructure/ui-icons/lib/IconCoursesSolid';
import IconCoursesLine from '@instructure/ui-icons/lib/IconCoursesLine';
import IconModule from '@instructure/ui-icons/lib/IconModuleSolid';
import IconModuleLine from '@instructure/ui-icons/lib/IconModuleLine';
import IconAssignment from '@instructure/ui-icons/lib/IconAssignmentSolid';
import IconAssignmentLine from '@instructure/ui-icons/lib/IconAssignmentLine';
import IconQuiz from '@instructure/ui-icons/lib/IconQuizSolid';
import IconQuizLine from '@instructure/ui-icons/lib/IconQuizLine';
import IconDiscussion from '@instructure/ui-icons/lib/IconDiscussionSolid';
import IconDiscussionLine from '@instructure/ui-icons/lib/IconDiscussionLine';
import IconDocument from '@instructure/ui-icons/lib/IconDocumentSolid';
import IconDocumentLine from '@instructure/ui-icons/lib/IconDocumentLine';
import IconVideo from '@instructure/ui-icons/lib/IconVideoSolid';
import IconVideoLine from '@instructure/ui-icons/lib/IconVideoLine';
import IconAudio from '@instructure/ui-icons/lib/IconAudioSolid';
import IconAudioLine from '@instructure/ui-icons/lib/IconAudioLine';
import IconImage from '@instructure/ui-icons/lib/IconImageSolid';
import IconImageLine from '@instructure/ui-icons/lib/IconImageLine';
import IconCollection from '@instructure/ui-icons/lib/IconCollectionSolid';
import IconCollectionLine from '@instructure/ui-icons/lib/IconCollectionLine';

const iconPaths = {
  course: { solid: IconCourses, line: IconCoursesLine },
  module: { solid: IconModule, line: IconModuleLine },
  assignment: { solid: IconAssignment, line: IconAssignmentLine },
  quiz: { solid: IconQuiz, line: IconQuizLine },
  discussion_topic: { solid: IconDiscussion, line: IconDiscussionLine },
  page: { solid: IconDocument, line: IconDocumentLine },
  document: { solid: IconCollection, line: IconCollectionLine },
  video: { solid: IconVideo, line: IconVideoLine },
  audio: { solid: IconAudio, line: IconAudioLine },
  image: { solid: IconImage, line: IconImageLine }
};

export default createReactClass({
  displayName: 'ResourceTypeIcon',

  propTypes: {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(iconPaths)).isRequired,
    solid: PropTypes.bool,
    colored: PropTypes.bool,
    raw: PropTypes.bool,
    ghost: PropTypes.bool,
  },

  render () {
    const { className, type, colored, solid, raw, ghost, iconSize, style } = this.props;
    const Icon = iconPaths[type] || iconPaths.course;
    return (
      <span
        aria-hidden="true"
        focusable="false"
        className={classnames(
          `type-${type}`,
          'ResourceTypeIcon',
          `ResourceTypeIcon--${type}`,
          className,
          ghost ? 'font-color' : (colored ? 'background-color' : ''),
          {
            'ResourceTypeIcon--raw': raw
          }
        )}
        style={{
          ...style
        }}
        data-automation="FeaturedResourceTypeIcon"
      >
        {solid ? <Icon.solid size={iconSize} /> : <Icon.line size={iconSize} />}
      </span>
    );
  }
});
