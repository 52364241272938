import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import classnames from 'classnames';
import _ from 'lodash';

import AnalyticsActions from '../../actions/analytics';
import Message from '../common/message';
import ComplexMessage from '../common/complex-message';
import FavoriteResourceCard from '../resources/favorite-resource-card';
import LoadingSpinner from '../common/loading-spinner';
import { getState } from '../../store';
import AlertFooterImportStatus from '../alert-footer/alert-footer-import-status';
import LoadMore from '../common/load-more';
import Link from '../common/link';

const isComplete = obj => obj.exportStatus === 'complete';

export default class FavoriteResourcesList extends React.Component {
  static propTypes = {
    baseParams: PropTypes.object,
    fetchNext: PropTypes.func.isRequired,
    results: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    emptyMessage: PropTypes.node.isRequired
  };

  constructor (props) {
    super(props);
    this.state = {
      isNewSearch: true
    };
    this.innerRef = React.createRef();
    this.props.fetchNext({
      ...props.baseParams,
      ...props.router.query
    });
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.state.isNewSearch && newProps.results.searchSuccessful) {
      this.setState({ isNewSearch: false });
      AnalyticsActions.viewedSearchResults(newProps.results.count);
    }

    if (this.props.router !== newProps.router) {
      const newParams = Object.assign(
        {},
        newProps.baseParams,
        newProps.router.query
      );
      this.props.fetchNext(newParams);
      this.setState({ isNewSearch: true });
    }
  }

  componentDidUpdate () {
    if (this.scrollToTop && this.innerRef && this.innerRef.current) {
      this.innerRef.current.scrollTop = 0;
      this.scrollToTop = false;
    }
  }

  getInvisibleImportStatus = visibleResources => {
    const { importStatus } = this.props;
    const visibleIds = visibleResources.map(item => item.id);
    const ids = Object.keys(importStatus);
    const invisibleIds = _.difference(ids, visibleIds);

    return invisibleIds
      .map(id => ({ ...importStatus[id] }))
      .filter(status => status.isImporting || status.error || status.showSuccess);
  };

  render () {
    const {
      startImport,
      getImportStatusForResource,
      dismissErrorForResource,
    } = this.props;
    const isFavoritesTraySet = getState().featureFlags.flags.favorites_tray;
    const params = Object.assign(
      {},
      this.props.baseParams,
      this.props.router.query
    );
    const fetchNext = () => this.props.fetchNext(params, this.props.results.nextCursor);

    const isLoading = this.props.results.store.showLoading();

    if (isLoading) {
      const results = this.props.results.store.get();
      const filteredResults = results.filter(isComplete);
      const invisbleImportStatus = this.getInvisibleImportStatus(filteredResults);
      return (
        <div className="AppHandler--loading">
          <LoadingSpinner />
          <AlertFooterImportStatus importStatus={invisbleImportStatus} onDismiss={dismissErrorForResource} />
        </div>
      );
    }

    const results = this.props.results.store.get();
    const filteredResults = results.filter(isComplete);
    const invisbleImportStatus = this.getInvisibleImportStatus(filteredResults);

    const listClassnames = classnames({
      'FavoriteResources-list': !isFavoritesTraySet,
      'FavoriteResources-list-favoritesTray': isFavoritesTraySet,
      'FavoriteResources-list-loading': this.props.results.loading,
      'FavoriteResources-list-empty': !filteredResults.length
    });
    const rootClassNames = classnames(
      {
        FavoriteSearchResults: !isFavoritesTraySet,
        'FavoriteSearchResults-favoritesTray': isFavoritesTraySet
      },
      'SearchResult'
    );

    if (this.scrollLock !== true && !_.isEqual(this.lastResults, filteredResults)) {
      this.scrollToTop = true;
    } else if (this.scrollLock === true && !_.isEqual(this.lastResults, filteredResults)) {
      this.scrollLock = false;
    }

    this.lastResults = filteredResults;

    return (
      <div data-testid="favorite-resources-list" ref={this.innerRef} className={rootClassNames}>
        <ul className={listClassnames}>
          {filteredResults.length
            ? filteredResults
              .map(learningObject => (
                <FavoriteResourceCard
                  startImport={startImport}
                  canvasUrl={this.props.session.canvasApiDomain}
                  courseId={this.props.session.canvasCourseId}
                  canvasResourceType={this.props.session.canvasResourceType}
                  courses={this.props.courses.courses}
                  key={learningObject.id}
                  importStatus={getImportStatusForResource(learningObject)}
                  dismissErrorForResource={dismissErrorForResource}
                  resource={learningObject}
                  returnUrl={this.props.session.returnUrl}
                />
              ))
            : this.props.emptyMessage}
          {/* The empty items ^ with zero height help align the last row
                of a flex-wrapped list */}
          {this.props.results.error && (
            <Message type="error2 message-with-icon">
              <ComplexMessage>
                {formatMessage(
                  'There was an error loading the results.\nYou can try again by {clicking_here}.',
                  { clicking_here: ComplexMessage.placeholder('retry') }
                )}
                <Link key="retry" onClick={fetchNext}>
                  {formatMessage('clicking here')}
                </Link>
              </ComplexMessage>
            </Message>
          )}
        </ul>
        <AlertFooterImportStatus importStatus={invisbleImportStatus} onDismiss={dismissErrorForResource} />
        <LoadMore
          hasMore={this.props.results.hasMore}
          isLoading={isLoading}
          loadMore={() => {
            this.scrollLock = true;
            fetchNext();
          }}
        />
      </div>
    );
  }
}
